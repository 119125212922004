import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MainService } from 'src/app/provider/main.service';

@Component({
  selector: 'app-cashback-view',
  templateUrl: './cashback-view.component.html',
  styleUrls: ['./cashback-view.component.css']
})
export class CashbackViewComponent implements OnInit {


  data: any = {}

  constructor(private route: ActivatedRoute, private router: Router, public service: MainService) {
    this.route.params.subscribe((params) => {
      this.data = params.data;
    });
  }

  ngOnInit(): void {
    console.log(this.data);
  }

}
