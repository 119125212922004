<main class="middle-content">
    <!-- Page Title Start -->
    <!-- Page Title End -->
    <div class="content-section">
        <div class="page_title_block pL0 pR0">
            <h1 class="page_title" style="color:black;font-size: 20px !important;">HOT Wallet Management</h1>
        </div>
        <div class="custom_tabs common-tabs">
            <div class="row mb20 justify-content-center">
                <div class="col-sm-12" style="padding: inherit;">
                    <ul class="nav nav-tabs d-flex  justify-content-center text-center">
                        <li class="nav-item flex-fill"> <a class="nav-link active show"
                                [ngClass]="{'active': currTab=='HOT'}" (click)="selectTab('HOT')"
                                href="javascript:;">Hot Wallet</a>
                        </li>
                        <li class="nav-item flex-fill"> <a class="nav-link active show"
                                [ngClass]="{'active': currTab=='COLD'}" (click)="selectTab('COLD')"
                                href="javascript:;">Hot Wallet to cold wallet transactions</a>
                        </li>
                    </ul>
                </div>
            </div>


            <div class="tab-content">
                <div class="tab-pane active show" *ngIf="this.currTab == 'HOT'">
                    <div class="tab-pane1">
                        <div class="table-responsive">
                            <table class="table table-bordered" aria-describedby="">
                                <thead>
                                    <tr class="no_wrap_th">
                                        <th id="">S.No</th>
                                        <th id="">Coin</th>
                                        <th id="">Wallet Address</th>
                                        <th id="">Last Withdraw On</th>
                                        <th id="">Hot Wallet</th>
                                        <th id="">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr
                                        *ngFor="let item of finalHotWalletArray | paginate:{itemsPerPage: 15, currentPage: pageNumber,totalItems:totalItems}; let i = index;">
                                        <td>{{i+1}}</td>
                                        <!-- <td>{{item?.data?.CoinName || "---"}}</td> -->
                                        <td>{{item?.data?.CoinName == 'AVT' ? 'CRED' :
                                            item?.data?.CoinName || 'N/A'}}</td>

                                        <td>
                                            <div *ngIf="item.data.HotWalletData.address!=null">
                                                {{(item.data.HotWalletData.address )}}<i class="fa fa-clone"
                                                    aria-hidden="true" style="cursor: pointer;margin-left: 2%;"
                                                    (click)="copyToClipboard(item.data.HotWalletData.address)"></i>
                                            </div>
                                            <div *ngIf="item.data.HotWalletData.address==null">
                                                ---
                                            </div>
                                        </td>
                                        <td>{{(item?.data.lastWithdrawTime | date:"medium")||'---'}}</td>

                                        <td>
                                            {{item?.data.HotWalletData.hotWalletBalance || '---'}}
                                            <!-- <input type="text" class="form-control" name="{{i}}" [(ngModel)]="item.hotWalletBalance"> -->
                                        </td>
                                        <td class="action_td_btn3">
                                            <!-- <button class="btn btn-success btn-sm" (click)="updateHotBalance(item.coinShortName)" *ngIf="!item.create_wallet && item.address != null">Update Balance</button>
                                          
                                            <button class="btn btn-warning btn-sm" (click)="createwalletCold(item?.coinShortName)"*ngIf="item.create_wallet">Create Wallet</button>
                                            <button class="btn btn-danger btn-sm" (click)="generateAddress(item?.coinShortName)"*ngIf="!item.create_wallet && item.address == null">Update Address</button> -->
                                            <button class="btn btn-warning btn-sm"
                                                (click)="transferBalance(item?.data.HotWalletData.coinType,item?.data.HotWalletData.address, item?.data.HotWalletData.hotWalletBalance, item?.data.HotWalletData.tag)">Transfer</button>
                                        </td>
                                    </tr>
                                    <tr *ngIf="finalHotWalletArray == ''">
                                        <td colspan="8" style="text-align: center;"> No wallet found</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div class="custom-pagination mt20 text-right" *ngIf="finalHotWalletArray.length > 15 ">
                            <pagination-controls (pageChange)="pageNumber = $event"></pagination-controls>
                        </div>


                    </div>
                </div>

                <div class="tab-pane active show" *ngIf="this.currTab == 'COLD'">
                    <div class="tab-pane1">
                        <!-- <label style="margin-left: 80%;">Search</label> &nbsp;
                                  <select style="width: 15%">
                                      <option value="">Select</option>
                                      <option value="Failed">Failed</option>
                                      <option value="Pending">Pending</option>
                                      <option value="Completed">Completed</option>
                                  </select>&nbsp; -->
                        <div class="table-responsive">
                            <table class="table table-bordered" aria-describedby="">
                                <thead>
                                    <tr class="no_wrap_th">
                                        <th id="">Transaction Id <i class="fa fa-sort"
                                                (click)="sortAgent('txnId');isAssending=!isAssending" aria-hidden="true"
                                                style="cursor: pointer;"></i></th>
                                        <th id="">Coin</th>
                                        <th id="">To Address <i class="fa fa-sort"
                                                (click)="sortAgent('address');isAssending=!isAssending"
                                                aria-hidden="true" style="cursor: pointer;"></i></th>
                                        <th id="">Txn. Hash <i class="fa fa-sort"
                                                (click)="sortAgent('txnHash');isAssending=!isAssending"
                                                aria-hidden="true" style="cursor: pointer;"></i></th>
                                        <th id="">Transaction Date and Time <i class="fa fa-sort"
                                                (click)="sortAgent('txnTime');isAssending=!isAssending"
                                                aria-hidden="true" style="cursor: pointer;"></i></th>
                                        <th id="">Transaction Status</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr
                                        *ngFor="let item of getAlltransactionsHistoryList | paginate:{itemsPerPage: 10, currentPage: pageNumber,totalItems:totalItems}; let i = index;">
                                        <td>{{item?.txnId}}</td>
                                        <td>{{item?.coinType || "---"}}</td>
                                        <td>
                                            <div *ngIf="item.address!=null">
                                                {{(item?.address)}}
                                                <i class="fa fa-clone" aria-hidden="true" style="cursor: pointer;"
                                                    (click)="copyToClipboard(item.address)"></i>
                                            </div>
                                            <div *ngIf="item.address==null">
                                                ---
                                            </div>


                                        </td>
                                        <td>
                                            <div *ngIf="item.txnHash==null">
                                                ---
                                            </div>
                                            <div *ngIf="item.txnHash!=null">
                                                {{(item.txnHash )}}
                                                <i class="fa fa-clone" aria-hidden="true" style="cursor: pointer;"
                                                    (click)="copyToClipboard(item.txnHash)"></i>
                                            </div>


                                        </td>
                                        <td>
                                            {{item?.txnTime | date:"medium"}}
                                            <!-- <input type="text" class="form-control" name="{{i}}" [(ngModel)]="item.hotWalletBalance"> -->
                                        </td>
                                        <td>{{item?.status || "---"}}</td>
                                        <!-- <td class="action_td_btn3">
                                            <button class="btn btn-success btn-sm" (click)="updateAddressModal(item?.coinShortName)" *ngIf="!item.create_wallet">Update Address</button>
                                            <button class="btn btn-success btn-sm" (click)="updateHotBalance(item.coinShortName)" *ngIf="!item.create_wallet && item.address != null">Update Balance</button>
                                            <button class="btn btn-warning btn-sm" (click)="createwalletCold(item?.coinShortName)" *ngIf="item.create_wallet">Create Wallet</button>
                                            <button class="btn btn-danger btn-sm" (click)="generateAddress(item?.coinShortName)"*ngIf="!item.create_wallet && item.address == null">Update Address</button>
                                        </td> -->
                                    </tr>
                                    <tr *ngIf="getAlltransactionsHistoryList == ''">
                                        <td colspan="8"> No transaction found</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div class="custom-pagination mt20 text-right"
                            *ngIf="getAlltransactionsHistoryList.length > 10 ">
                            <pagination-controls (pageChange)="pageNumber = $event"></pagination-controls>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal fade global-modal reset-modal" id="updateAddress1">
                <div class="modal-dialog max-WT-500">
                    <form class="change_password">
                        <div class="modal-content">
                            <div>
                                <button type="button" class="close" data-dismiss="modal">&times;</button>
                                <div class="modal-body">
                                    <div
                                        class="text-center modal_flax_height d-flex align-items-center justify-content-center">
                                        <div class="w-100">
                                            <p>Please provide the new address</p>
                                            <div class="form-group row align-items-baseline">
                                                <div class="form-group row align-items-center">
                                                    <label class="col-md-5">Your address :</label>
                                                    <div class="col-md-7">

                                                        <input type="email" id="emailid"
                                                            class="form-control form-control2"
                                                            placeholder="Enter new address" style=" width: 200px;"
                                                            name="address" [(ngModel)]="addresObj.address">
                                                    </div>
                                                </div>
                                                <div>
                                                    <br>
                                                    <button type="submit" class="btn btn-info mr10" data-dismiss="modal"
                                                        [disabled]="!addresObj.address" (click)="submitAddress()" style="
                                                    text-align: center;
                                                    /* padding: 156px; */
                                                    padding: left;
                                                    margin-left: 156px;
                                                ">Submit</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</main>

<div class="modal fade global-modal reset-modal" id="transferModal">
    <div class="modal-dialog max-WT-500">
        <form class="change_password" [formGroup]="transferForm">
            <div class="modal-content">
                <div>
                    <div class="modal-body">
                        <h5 style="text-align: center; ">Total Available Balance {{hotbalance}} {{hotcoinname}}</h5>

                        <div class="text-center modal_flax_height d-flex align-items-center justify-content-center">
                            <div class="w-100">
                                <br>
                                <div class="hot-coldpopup mb-4">
                                    <label>Cold Wallet Address: </label> <input style="width: 59%;" type="text"
                                        formControlName="coldAddress"><br>

                                </div>

                                <div class="hot-coldpopup mb-4">
                                    <label>Hot Wallet Address: </label> <input style="width: 59%;" type="text"
                                        formControlName="transferAmount" [value]="hotcoinaddr" readonly>
                                    <span class="error">
                                        <small
                                            *ngIf="transferForm.get('transferAmount').hasError('pattern') && transferForm.get('transferAmount').dirty"
                                            class="error" padding>*Please enter valid cold amount</small>
                                    </span>
                                </div>

                                <div class="hot-coldpopup mb-4">
                                    <label>Amount To Transfer: </label> <input style="width: 59%;" type="text"
                                        formControlName="transferrAmount"><br>
                                    <span class="error">
                                        <small
                                            *ngIf="transferForm.get('transferrAmount').hasError('pattern') && transferForm.get('transferrAmount').dirty"
                                            class="error" padding>*Please enter valid amount</small>
                                    </span>
                                </div>

                                <div *ngIf="hotcoinname == 'XRP'" class="hot-coldpopup mb-4">
                                    <label style=" margin-left: 95px;">Tag : </label> <input style="width: 59%;"
                                        type="text" formControlName="tag"><br>
                                    <span class="error">
                                        <small
                                            *ngIf="transferForm.get('tag').hasError('pattern') && transferForm.get('tag').dirty"
                                            class="error" padding>*Please enter tag.</small>
                                    </span>
                                </div>
                                <div *ngIf="hotcoinname == 'XLM'" class="hot-coldpopup mb-4">
                                    <label>Tag : </label> <input style="width: 59%;" type="text"
                                        formControlName="tag"><br>
                                    <span class="error">
                                        <small
                                            *ngIf="transferForm.get('tag').hasError('pattern') && transferForm.get('tag').dirty"
                                            class="error" padding>*Please enter tag.</small>
                                    </span>
                                </div>

                                <div class="buttons-alignments d-flex align-items-center justify-content-center">
                                    <!-- <button type="submit" class="btn btn-info mr-4" (click)="transferHotToCold()"
                                              >Transfer Now</button> -->
                                    <button type="submit" class="btn btn-info mr-4"
                                        (click)="transferHotToCold()">Transfer Now</button>
                                    <button type="button" class="btn btn-danger" data-dismiss="modal">Cancel</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>