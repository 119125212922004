<main class="middle-content">
    <div class="page_title_block">
        <h1 class="page_title" style="color:#26272d">Payment Management</h1>
    </div>
    <!-- <div class="row">
        <div class="col-md-12">
         <div class="d-flex justify-content-end">
            <h6 class="kycReqired">User Kyc Required</h6>
            <input class="top-check-data" type="checkbox" checked data-toggle="toggle" data-on="YES" data-off="NO">
         </div>
        </div>
    </div> -->
    <div class="content-section">
        <div class="outer-box">
            <div class="global-table no-radius p0">
                <div class="tab-content1">
                    <div class="tab-pane1">
                        <div class="user-main-head sec_head_new sec_head_new_after">
                            <form [formGroup]="kycForm">
                                <div class="row form-group">

                                    <div class="col-lg-3 col-md-3 col-sm-6 ">
                                        <div class="head_flt_select">
                                            <div class="head_flt_select input-label input-label1">
                                                <span class="d-flex align-items-center"
                                                    style="margin-left: 5px!important">From Date </span>

                                                <input type="datetime-local" class="form-control form"
                                                    formControlName="startdate" [max]="today">
                                            </div>
                                        </div>

                                    </div>
                                    <div class="col-lg-3 col-md-3 col-sm-6">

                                        <div class="head_flt_select">
                                            <div class="head_flt_select input-label input-label1">
                                                <span class="d-flex align-items-center">To Date</span>
                                                <input type="datetime-local" class="form-control form"
                                                    [readonly]="!this.kycForm.controls['startdate'].value"
                                                    [min]="kycForm.controls['startdate'].value"
                                                    formControlName="enddate">
                                            </div>
                                        </div>

                                    </div>
                                    <div class="col-lg-4 col-md-4 col-sm-6 ">
                                        <div class="head_flt_select">
                                            <div class="head_flt_select input-label input-label1">
                                                <span class="d-flex align-items-center op-0"
                                                    style="margin-left: 5px!important">Search</span>

                                                <input type="text" class="form-control form"
                                                    formControlName="searchUsername"
                                                    placeholder="Search by username/email">
                                            </div>
                                        </div>

                                    </div>

                                    <div class="col-md-4 col-sm-12 user-lst" style="margin-top: 21px;">
                                        <div class="">
                                            <div class="text-left">
                                                <input type="text" placeholder="Enter TxnId" class="form-control form "
                                                    formControlName="status">
                                            </div>
                                        </div>
                                    </div>
                                    <!-- <div class="col-md-2 col-sm-6 user-lst" >
                                        <div class="two-btnline">
                                            <div class="text-left" style="margin-left: 1%" (click)="pageNumber == 1;search()">
                                                <button type="submit" class="btn  btn-theme cus-mr"
                                                    >Search</button>
                                            </div>
                                        </div>
                                        
                                    </div>
                                    <div class="col-md-2 col-sm-6 user-lst">
                                      
                                        <div class="two-btnline sm-left">
                                            <div class="text-left" style="margin-left: 5px" (click)="reset()">
                                                <button type="submit" class="btn  btn-theme cus-mr"
                                                    >Reset</button>
                                            </div>
                                        </div>
                                    </div> -->

                                </div>
                                <div class="col-md-12 d-flex justify-content-end" style="margin-top: 23px;">
                                    <div class="">
                                        <div class="two-btnline">
                                            <div class="text-left" style="margin-left: 1%"
                                                (click)="pageNumber = 1;search()">
                                                <button type="submit" class="btn  btn-theme cus-mr">Search</button>
                                            </div>
                                        </div>

                                    </div>
                                    <div class="">

                                        <div class="two-btnline sm-left">
                                            <div class="text-left" style="margin-left: 5px" (click)="reset()">
                                                <button type="submit" class="btn  btn-theme cus-mr">Reset</button>
                                            </div>
                                        </div>
                                    </div>


                                    <div class="">
                                        <div class="two-btnline sm-left">
                                            <div class="text-left" style="margin-left: 10px" (click)="exportAsXLSX()">

                                                <button type="submit" class="btn  btn-theme cus-mr">EXPORT AS EXCEL
                                                </button>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </form>
                        </div>
                        <!-- <div class="col-md-12 col-sm-12 exprt" >

                            <button type="submit"  class="btn  btn-theme " (click)="exportAsXLSX()">EXPORT AS EXCEL
                            </button>
                        </div> -->

                        <div class="table-responsive">
                            <table class="table table-bordered" aria-describedby="user list">
                                <thead>
                                    <tr class="no_wrap_th">
                                        <th scope="col">S.No</th>
                                        <!-- <th scope="col">User Id</th>
                                        <th scope="col">Payment Id</th> -->
                                        <th scope="col">Username <i class="fa fa-sort"
                                                (click)="sortAgent('userName');isAssending=!isAssending"
                                                aria-hidden="true" style="cursor: pointer;"></i></th>
                                        <th scope="col">Email <i class="fa fa-sort"
                                                (click)="sortAgent('Email');isAssending=!isAssending" aria-hidden="true"
                                                style="cursor: pointer;"></i></th>

                                        <th scope="col">Customer Id <i class="fa fa-sort"
                                                (click)="sortAgent('customer');isAssending=!isAssending"
                                                aria-hidden="true" style="cursor: pointer;"></i></th>
                                        <th scope="col">Amount <i class="fa fa-sort"
                                                (click)="sortAgent('amount');isAssending=!isAssending"
                                                aria-hidden="true" style="cursor: pointer;"></i></th>
                                        <th scope="col">Currency <i class="fa fa-sort"
                                                (click)="sortAgent('currency');isAssending=!isAssending"
                                                aria-hidden="true" style="cursor: pointer;"></i></th>
                                        <th scope="col">TxnId <i class="fa fa-sort"
                                                (click)="sortAgent('txnId');isAssending=!isAssending" aria-hidden="true"
                                                style="cursor: pointer;"></i></th>
                                        <!-- <th scope="col">Card Id</th> -->
                                        <th scope="col">Payment Status <i class="fa fa-sort"
                                                (click)="sortAgent('paymentStatus');isAssending=!isAssending"
                                                aria-hidden="true" style="cursor: pointer;"></i></th>
                                        <th scope="col">Date & Time <i class="fa fa-sort"
                                                (click)="sortAgent('createTime');isAssending=!isAssending"
                                                aria-hidden="true" style="cursor: pointer;"></i></th>
                                        <th scope="col">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr
                                        *ngFor="let data of paymentData | paginate:{itemsPerPage:10, currentPage:pageNumber, totalItems:totalRecords}; let i=index">
                                        <td>{{10*(this.pageNumber-1)+i+1}}</td>
                                        <td>{{data.userName || 'N/A'}}</td>
                                        <td>{{data.email || 'N/A'}}</td>
                                        <!-- <td>{{data.nubmer || 'N/A'}}</td> -->
                                        <td>{{data.customer || 'N/A'}}</td>
                                        <td>{{data.amount || 'N/A'}}</td>
                                        <td>{{data.currency || 'N/A'}}</td>
                                        <td>{{data.txnId || 'N/A'}} <i class="fa fa-clone cop-ico"
                                                (click)="copyToClipboard(data.txnId)" aria-hidden="true"></i>
                                        </td>
                                        <!-- <td>{{data.cardId || 'N/A'}}</td> -->
                                        <td>{{data.paymentStatus || 'N/A'}}</td>
                                        <td>{{(data.createTime | date: 'medium')}}</td>
                                        <td class="action_td_btn3">
                                            <a routerLink="/payment-view/{{data.userId}}"><i class="fa fa-eye"
                                                    aria-hidden="true"></i></a>

                                        </td>
                                    </tr>
                                    <tr *ngIf="paymentData?.length==0">
                                        <td colspan="12" vertical-align="middle">
                                            <div class="no-record">
                                                <div class="no-recordin">
                                                    <H5 style="">No record found</H5>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div class="custom-pagination mt20 " style="display: flex; justify-content:flex-end"
                            *ngIf="totalRecords > 10">
                            <pagination-controls (pageChange)="pagination($event)"></pagination-controls>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</main>

<footer>
    <div class="container-fluid">
        <p class="copyright">CRED Development© 2022 All Rights Reserved. <strong class="theme-text-color"></strong>
        </p>
    </div>
</footer>