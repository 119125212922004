<div class="wrapper">
    <main class="middle-content">
        <div class="page_title_block" style="margin-top: 5em !important;">
            <h1 class="page_title">Community / Add</h1>
        </div>
        <div class="content-section">
            <div class="order-view  max-WT-900 mrgn-0-auto setting-page">
                <div class="  mb40">
                    <div class="add-store-block input-style mb20 mt20 view-label-align">
                        <form [formGroup]="addSwatchesForm" #f="ngForm">
                            <div class="form-group row align-items-baseline">
                                <label class="col-md-5 textalignright ">Community Name <span>:</span></label>
                                <div class="col-md-7 textaligncenter">
                                    <div class="form-group">
                                        <!-- <input type="text" class="form-control" placeholder="Enter Community Name"
                                            formControlName="language" (keypress)="service.preventSpace($event)"
                                            maxlength="60"> -->
                                        <select formControlName="language" class="form-control form1">
                                            <option value="">Select Community</option>
                                            <option value="FACEBOOK">FACEBOOK</option>
                                            <option value="DISCORD">DISCORD</option>
                                            <option value="TWITTER">TWITTER</option>
                                            <option value="INSTAGRAM">INSTAGRAM</option>
                                            <option value="TELEGRAM">TELEGRAM</option>

                                            <!-- <option value="DELETED">Delete</option> -->
                                        </select>
                                        <!-- <i *ngIf="imageUrl"   (click)="remove(1)" class="fa fa-trash new-delete" aria-hidden="true"></i>
                                        <button *ngIf="imageUrl" class="view-img" [disabled]="!imageUrl"><a [href]="imageUrl" target="_blank"><i class="fa fa-eye" aria-hidden="true"></i></a></button> -->
                                    </div>
                                </div>
                            </div>
                            <div class="form-group row align-items-baseline">
                                <label class="col-md-5 textalignright ">Community Link <span>:</span></label>
                                <div class="col-md-7 textaligncenter">
                                    <div class="form-group">
                                        <input type="text" class="form-control" placeholder="Enter Community Link"
                                            formControlName="link" (keypress)="service.preventSpace($event)"
                                            maxlength="60">

                                        <!-- <i *ngIf="imageUrl"   (click)="remove(1)" class="fa fa-trash new-delete" aria-hidden="true"></i>
                                        <button *ngIf="imageUrl" class="view-img" [disabled]="!imageUrl"><a [href]="imageUrl" target="_blank"><i class="fa fa-eye" aria-hidden="true"></i></a></button> -->
                                    </div>
                                </div>
                            </div>

                            <!-- <div class="form-group row align-items-baseline">
                                <label class="col-md-5 textalignright ">Whitepaper  France <span>:</span></label>
                                <div class="col-md-7 textaligncenter">
                                    <div class="form-group d-flex">
                                        <input type="file" formControlName="image" name="image"
                                            accept=".pdf" id="image"
                                            (change)="uploadImg($event,'FRANCE')" >
                                            <i *ngIf="imageUrl1"   (click)="remove(2)" class="fa fa-trash new-delete" aria-hidden="true"></i>
                                            <button *ngIf="imageUrl1" class="view-img" [disabled]="!imageUrl1"><a [href]="imageUrl" target="_blank"><i class="fa fa-eye" aria-hidden="true"></i>
                                            </a></button>
                                    </div>
                                </div>

                            </div>
                            <div class="form-group row align-items-baseline">
                                <label class="col-md-5 textalignright ">Whitepaper  Arabic <span>:</span></label>
                                <div class="col-md-7 textaligncenter">
                                    <div class="form-group d-flex">
                                        <input type="file" formControlName="image" name="image"
                                            accept=".pdf" id="image"
                                            (change)="uploadImg($event,'ARABIC')" >
                                            <i *ngIf="imageUrl2"   (click)="remove(3)" class="fa fa-trash new-delete" aria-hidden="true"></i>
                                            <button *ngIf="imageUrl2" class="view-img" [disabled]="!imageUrl2"><a [href]="imageUrl" target="_blank"><i class="fa fa-eye" aria-hidden="true"></i>
                                            </a></button>
                                    </div>
                                </div>

                            </div> -->

                        </form>
                    </div>
                    <div class="text-center mt40 mb40">
                        <button class="btn btn-large  max-WT-150 btn-theme" routerLink="/community-list">Back</button>
                        <button class="btn btn-large  max-WT-150 btn-theme ml-3"
                            (click)="addWhitePaper()">Submit</button>
                    </div>
                </div>
            </div>
        </div>
    </main>
</div>

<footer>
    <div class="container-fluid">
        <p class="copyright">CRED Development© 2022 All Rights Reserved. <strong class="theme-text-color"></strong>
        </p>
    </div>
</footer>