<main class="middle-content">
    <div class="content-section">
        <div class="page_title_block pL0 pR0">
            <h1 class="page_title" style="color:#26272d">ADMIN DETAIL</h1>
        </div>
        <div class="order-view mt30 max-WT-700 mrgn-0-auto">
            <div class="main-block-innner mb40 mt40">
                <div class="user-profile">
                    <div class="image-box">
                        <img [src]="imageUrl || 'assets/img/profile-img.jpg'" class="profile-pic" alt="profile image">
                    </div>
                </div>
                <div class="add-store-block input-style" style="margin-left: 27%;">

                    <div class="form-group row">
                        <label class="col-md-5 text" for="">Name</label>
                        <span class="col-md-1">:</span>
                        <p class="col-md-6">{{viewData?.firstName || 'N/A'}}</p>
                    </div>
                    <div class="form-group row">
                        <label class="col-md-5 text" for="">Email</label>
                        <span class="col-md-1">:</span>
                        <p class="col-md-6">{{viewData?.email || 'N/A'}}</p>
                    </div>
                    <div class="form-group row">
                        <label class="col-md-5 text" for="">Mobile Number</label>
                        <span class="col-md-1">:</span>
                        <p class="col-md-6">{{viewData?.phoneNo || 'N/A'}}</p>
                    </div>
                    <div class="form-group row">
                        <label class="col-md-5 text" for="">Address</label>
                        <span class="col-md-1">:</span>
                        <p class="col-md-6">{{viewData?.address || 'N/A'}}</p>
                    </div>
                </div>
            </div>
            <div class="text-left mt40 text-center">
                <a routerLink="/user-management-admin" class="btn btn-large  max-WT-200 font-100 btn-green">Back</a>
            </div>
        </div>
    </div>
</main>

<footer>
    <div class="container-fluid">
        <p class="copyright">CRED Development© 2022 All Rights Reserved. <strong class="theme-text-color"></strong>
        </p>
    </div>
</footer>