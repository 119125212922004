import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { MainService } from 'src/app/provider/main.service';

@Component({
  selector: 'app-cashback',
  templateUrl: './cashback.component.html',
  styleUrls: ['./cashback.component.css']
})
export class CashbackComponent implements OnInit {

  userForm: FormGroup;
  ticketList: any = [];
  page: number = 1;
  limit: number = 10;
  itemsPerPage: number = 10;
  cashbackLimit: any
  totalRecords: any;
  pageNumber: number = 1;
  arr: any = ['RESOLVED', 'INPROGRESS', 'CLOSED', 'CANCELLED']
  updatestatus: string;
  isAssending: boolean = true;
  today: any = new Date().toISOString().slice(0, 16)

  constructor(
    private router: Router, public service: MainService) { }

  ngOnInit() {
    this.userForm = new FormGroup({
      'startdate': new FormControl('', Validators.required),
      'enddate': new FormControl('', Validators.required),
      'searchText': new FormControl(''),
      'status': new FormControl(''),

    })
    this.getAdminCashback();
    this.usersCashbackList();
  }

  // update cashback amount

  addCashback() {
    let url = `account/admin/user-management/admin-referal-add`
    let data = {
      cashBackPercentage: +this.cashbackLimit
    }
    this.service.post(url, data).subscribe((res: any) => {
      if (res['status'] == 200) {
        this.getAdminCashback();

        this.service.hideSpinner();
        this.service.toasterSucc(res.message);
      } else {
        this.service.hideSpinner();
        this.service.toasterErr(res.message)
      }
    }, (err) => {
      this.service.hideSpinner();
      this.service.toasterErr(err.error.message);
    })
  }

  // referal amount list

  planData: any
  getAdminCashback() {
    let url = `account/admin/user-management/get-admin-referal`
    this.service.get(url).subscribe((res: any) => {
      if (res['status'] == 200) {
        this.planData = res['data']
        this.service.hideSpinner();
        this.service.toasterSucc(res.message);
      } else {
        this.service.hideSpinner();
        this.service.toasterErr(res.message)
      }
    }, (err) => {
      this.service.hideSpinner();
      this.service.toasterErr(err.error.message);
    })
  }

  // users referral lists
  usersDetils: any
  usersCashbackList() {
    let search = this.userForm.controls.searchText.value;
    let url = `wallet/get-all-transaction-history?page=0&pageSize=10&txnType=CASHBACK`
    this.service.get(url).subscribe((res: any) => {
      if (res['status'] == 200) {
        this.usersDetils = res['data']['resultlist']
        console.log(this.usersDetils);

        this.service.hideSpinner();
        this.service.toasterSucc(res.message);
      } else {
        this.service.hideSpinner();
        this.service.toasterErr(res.message)
      }
    }, (err) => {
      this.service.hideSpinner();
      this.service.toasterErr(err.error.message);
    })
  }



  isSearched: boolean = false;

  pagination(page) {
    // this.totalRecords = [];
    console.log("jh", page);
    this.pageNumber = page;
    console.log("jh", this.pageNumber);
    if (this.isSearched) {


      // this.filterTicketList();
    } else {
      this.service.hideSpinner();
      // this.getTicketList(page);
    }
  }
  updateTicketStatus(e, id: number) {
    let status = e.target.value
    id = id
    let data = {}
    this.service.post(`static/change-ticket-status?ticketId=${id}&ticketStatus=${status}`, data).subscribe((res: any) => {
      this.service.showSpinner()
      if (res.status = 200) {
        this.service.hideSpinner()
        this.service.toasterSucc(res.message)
      }
    })
  }

  // reset
  // reset(){
  //   this.userForm.reset()
  //   this.getTicketList(this.page)
  // }
  reset() {
    if (
      this.userForm.value.startdate ||
      this.userForm.value.enddate ||
      this.userForm.value.searchText ||
      this.userForm.value.status
    ) {
      this.userForm.reset({
        startdate: "",
        // userStatus: "",
        enddate: "",
        searchText: "",
        status: "",
      });
      // this.getTicketList(this.page);
    }
  }
  replyTicket() {
    this.router.navigate(['/reply-ticket'])
  }
  viewTicket() {
    this.router.navigate(['/view-ticket'])
  }

  //export User
  exportAsXLSX() {
    let dataArr = [];
    this.ticketList.forEach((element, ind) => {

      dataArr.push({
        "S no": ind + 1,
        "Ticket ID": element.ticketId ? element.ticketId : '',
        "Message": element?.description ? element?.description : '',
        "Email": element.email ? element.email : 'N/A',
        "Customer Name": element.firstName + '' + element.lastName ? element.firstName + " " + (element.lastName ? element.lastName : '') : '',
        "Date": element.createdAt ? element.createdAt.slice(0, 10) : 'N/A',
        "Status": element.ticketStatus,
      })
    })

    this.service.exportAsExcelFile(dataArr, 'Ticket Management');
    console.log(dataArr)
  }
  // sorting
  sortAgent(key) {
    if (this.isAssending) {
      this.ticketList = this.ticketList.sort((a, b) => {
        return a[key] > b[key] ? 1 : -1;
      });
    } else {
      this.ticketList = this.ticketList.sort((a, b) => {
        return a[key] < b[key] ? 1 : -1;
      });
    }
  }

}
