<!-- <app-sidemenu></app-sidemenu> -->
<div class="wrapper">

    <!-- Header End -->


    <!-- Aside End -->
    <main class="middle-content">
        <!-- Page Title Start -->
        <div class="page_title_block">
            <h1 class="page_title" style="color:black">View kyc</h1>
            <!-- <div class="common-bredcrumb">
                <ol class="breadcrumb">

                    <li class="breadcrumb-item active">User Management</li>
                </ol>

            </div> -->
        </div>
        <!-- Page Title End -->
        <div class="content-section">
            <div class="outer-box">
                <!-- Gloabl Table Box Start -->
                <div class="global-table no-radius p0">
                    <div class="tab-content1">
                        <div class="tab-pane1">

                            <div class="col">
                                <div class="card" style="width: 80%;margin-left: 13%;">

                                    <div class="card-body">
                                        <div class="row">
                                            <!-- <div class="col-md-12"> -->
                                            <div class="col-lg-6 col-md-12">
                                                <div class="row">
                                                    <label class="col-md-5 bold">KYC ID </label>
                                                    <span class="col-md-1">:</span>
                                                    <span class="col-md-5">{{data?.kycId || '---'}}</span>
                                                </div>
                                                <div class="row">
                                                    <label class="col-md-5 bold">KYC Status </label>
                                                    <span class="col-md-1">:</span>
                                                    <span class="col-md-5">{{data?.kycStatus || '---'}}</span>
                                                </div>
                                                <div class="row">
                                                    <label class="col-md-5 bold">Created On </label>
                                                    <span class="col-md-1">:</span>
                                                    <span class="col-md-5">{{(data?.createTime | date: "medium") ||
                                                        '---' }}</span>
                                                </div>
                                                <!-- <div class="row">
                                                        <label class="col-md-4">Type </label>
                                                        <span class="col-md-1">:</span>
                                                        <span class="col-md-5">{{kycDocList?.docName || '----'}}</span>
                                                    </div> -->

                                                <div class="row">
                                                    <label class="col-md-5 bold">Document Status</label>
                                                    <span class="col-md-1">:</span>
                                                    <span class="col-md-5">{{kycDocList?.documentStatus ||
                                                        '---'}}</span>
                                                </div>
                                                <div class="row">
                                                    <label class="col-md-5 bold">Wallet Address</label>
                                                    <span class="col-md-1">:</span>
                                                    <span class="col-md-5">{{kycDocList?.metamaskWalletAddress ||
                                                        '---'}}</span>
                                                </div>

                                            </div>
                                            <div class="col-lg-6 col-md-12">

                                                <div class="row">
                                                    <label class="col-md-5 bold">ID Document Type </label>
                                                    <span class="col-md-1">:</span>
                                                    <span class="col-md-5">{{kycDocList?.docName || '---'}}</span>
                                                </div>
                                                <div class="row">
                                                    <label class="col-md-5 bold">ID Number </label>
                                                    <span class="col-md-1">:</span>
                                                    <span class="col-md-5">{{kycDocList?.docIdNumber ||
                                                        '---'}}</span>
                                                </div>

                                                <!-- <div class="row">
                                                    <label class="col-md-4">ID Document Type </label>
                                                    <span class="col-md-1">:</span>
                                                    <span class="col-md-5">{{kycDocList?.docName2 || '---'}}</span>
                                                </div> -->
                                                <div class="row">
                                                    <label class="col-md-5 bold">Aadhaar Card Number </label>
                                                    <span class="col-md-1">:</span>
                                                    <span class="col-md-5">{{kycDocList?.aadharcardNo ||
                                                        '---'}}</span>
                                                </div>
                                                <!-- <div class="row">
                                                    <label class="col-md-4">ID Document Type </label>
                                                    <span class="col-md-1">:</span>
                                                    <span class="col-md-5">{{kycDocList?.docName2 || '---'}}</span>
                                                </div> -->
                                                <div class="row">
                                                    <label class="col-md-5 bold">PAN Card Number </label>
                                                    <span class="col-md-1">:</span>
                                                    <span class="col-md-5">{{kycDocList?.pancardNo ||
                                                        '---'}}</span>
                                                </div>

                                            </div>
                                            <!-- </div> -->


                                        </div>
                                    </div>
                                </div>

                                <div class="row" style="margin-top: 3%">
                                    <div class="col" style="text-align: center;position: relative; left: 7%;">
                                        <img [src]="kycDocList?.frontIdUrl || 'assets/img/profile-img.jpg'" class="zoom"
                                            style="max-width: 50%;"><br>
                                        <label>(Front)</label>
                                    </div>
                                    <div class="col" style="text-align: center;">
                                        <img [src]="kycDocList?.backIdUrl || 'assets/img/profile-img.jpg'" class="zoom"
                                            alt="" style="max-width: 50%;"><br>
                                        <label>(Back)</label>
                                    </div>
                                </div>

                                <div class="row" style="margin-top: 3%">
                                    <div class="col" style="text-align: center;position: relative; left: 7%;">
                                        <img [src]="kycDocList?.imageUrl1 || 'assets/img/profile-img.jpg'" class="zoom"
                                            style="max-width: 50%;"><br>
                                        <label>(Front)</label>
                                    </div>
                                    <div class="col" style="text-align: center;">
                                        <img [src]="kycDocList?.imageUrl2 || 'assets/img/profile-img.jpg'" class="zoom"
                                            alt="" style="max-width: 50%;"><br>
                                        <label>(Back)</label>
                                    </div>
                                </div>
                                <div class="row" style="margin-top: 3%">
                                    <div class="col" style="text-align: center;position: relative; left: 7%;">
                                        <img [src]="kycDocList?.imageUrl3 || 'assets/img/profile-img.jpg'" class="zoom"
                                            style="max-width: 50%;"><br>
                                        <label>(Front)</label>
                                    </div>
                                    <div class="col" style="text-align: center;">
                                        <img [src]="kycDocList?.imageUrl4 || 'assets/img/profile-img.jpg'" class="zoom"
                                            alt="" style="max-width: 50%;"><br>
                                        <label>(Back)</label>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>

                </div>
                <div style="text-align: center; margin-top: 9%; margin-bottom:3% ;">
                    <div>
                        <span *ngIf="ButtonDisable == 'PENDING'">
                            <button class="btn btn-theme ml-1" style="width: 15%"
                                (click)="approveKyc()">Approve</button>
                            <button class="btn btn-theme ml-1" style="width: 15%" (click)="openModal()">Reject</button>
                        </span>
                        <button class="btn btn-theme ml-1" style="width: 15%" (click)="back()">Back</button>

                    </div>
                </div>

            </div>
        </div>
        <!-- Table Responsive End -->

    </main>
</div>
<!-- Wrapper End -->
<!-- approve_modal Start -->
<div class="modal fade global-modal reset-modal" id="approveModal">
    <div class="modal-dialog max-WT-500">
        <form class="change_password">
            <div class="modal-content">
                <div>
                    <div class="modal-body">
                        <div class="text-center modal_flax_height d-flex align-items-center justify-content-center">
                            <div class="w-100">
                                <p>Are you sure you want to approve this document?</p>
                                <div>
                                    <button type="submit" class="btn btn-info mr-2"
                                        (click)="approveDocFunc()">Yes</button>
                                    <button type="button" class="btn btn-danger" data-dismiss="modal">No</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>
<!-- approve_modal End -->
<!-- reject_modal Start -->
<div class="modal fade global-modal reset-modal" id="reject">
    <div class="modal-dialog max-WT-500">
        <form class="change_password">
            <div class="modal-content">
                <div>
                    <div class="modal-body">
                        <div class="text-center modal_flax_height d-flex align-items-center justify-content-center">
                            <div class="w-100">
                                <p>Are you sure you want to reject this document?</p>
                                <textarea type="text" placeholder="Specify reason for rejection.."
                                    [(ngModel)]="rejectionReason" class="form-control"
                                    [ngModelOptions]="{standalone: true}"></textarea>
                                <div style="margin-top: 11px">
                                    <button type="submit" class="btn btn-info mr-2" (click)="rejectKyc()">Yes</button>
                                    <button type="button" class="btn btn-danger" data-dismiss="modal">No</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>
<!-- reject_modal End -->