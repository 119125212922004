<main class="middle-content">
    <div class="content-section">
        <div class="page_title_block pL0 pR0">
            <h1 class="page_title" style="color:#26272d">TOKEN MANAGEMENT</h1>
        </div>
        <div class="custom_tabs common-tabs">
            <div class="row mb20 justify-content-center">
                <div class="col-sm-12">
                    <ul class="nav nav-tabs d-flex  justify-content-center text-center">
                        <li class="nav-item flex-fill"> <a class="nav-link active show"
                                [ngClass]="{'active': currTab=='Price'}" (click)="selectTab('Price')"
                                href="javascript:;">Token Price</a>
                        </li>
                        <!-- <li class="nav-item flex-fill"> <a class="nav-link active show"
                                [ngClass]="{'active': currTab=='User'}" (click)="selectTab('User')"
                                href="javascript:;">User Transaction</a>
                              
                        </li> -->
                        <!-- <li class="nav-item flex-fill"> <a class="nav-link active show"
                                [ngClass]="{'active': currTab=='Admin'}" (click)="selectTab('Admin')"
                                href="javascript:;">Admin Transfer</a>
                        </li> -->
                        <li class="nav-item flex-fill"> <a class="nav-link active show"
                                [ngClass]="{'active':currTab=='Transfer'}" (click)="selectTab('Transfer')"
                                href="javascript:;">Transfer</a>
                        </li>
                        <li class="nav-item flex-fill"> <a class="nav-link active show"
                                [ngClass]="{'active':currTab=='History'}" (click)="selectTab('History')"
                                href="javascript:;">Transfer History</a>
                        </li>

                    </ul>
                </div>
            </div>
        </div>
        <!-- tab for user start -->
        <div class="custom_tabs common-tabs" *ngIf="this.currTab == 'User'  ">
            <div class="row mb20 justify-content-center">
                <div class="col-sm-12">
                    <ul class="nav nav-tabs d-flex  justify-content-center text-center">
                        <li class="nav-item flex-fill"> <a class="nav-link active show" [ngClass]="{'active': mytab }"
                                (click)="select('Buy')" href="javascript:;">Buy History</a>
                        </li>
                        <li class="nav-item flex-fill"> <a class="nav-link active show"
                                [ngClass]="{'active': tab=='Withdraw'}" (click)="select('Withdraw')"
                                href="javascript:;">Withdrawl History</a>
                        </li>


                    </ul>
                </div>
            </div>
        </div>
        <!-- tab for user end -->
        <!-- tab for admin start -->
        <div class="custom_tabs common-tabs" *ngIf="this.currTab == 'Admin' ">
            <div class="row mb20 justify-content-center">
                <div class="col-sm-12">
                    <ul class="nav nav-tabs d-flex  justify-content-center text-center">
                        <li class="nav-item flex-fill"> <a class="nav-link active show" [ngClass]="{'active': mytab1 }"
                                (click)="select('Transfer')" href="javascript:;">Transfer</a>
                        </li>
                        <li class="nav-item flex-fill"> <a class="nav-link active show"
                                [ngClass]="{'active':tab=='History'}" (click)="select('History')"
                                href="javascript:;">Transfer History</a>
                        </li>


                    </ul>
                </div>
            </div>
        </div>
        <!-- tab for admin end -->

        <!-- tab price start -->
        <div class="tab-pane active show" *ngIf="this.currTab == 'Price'">
            <div class="order-view mt30 max-WT-700 mrgn-0-auto">
                <form [formGroup]="priceForm">
                    <div class="main-block-innner mb40 mt40">
                        <div class="add-store-block input-style">

                            <div class="form-group row align-items-baseline">
                                <label class="col-md-6">Total Balance</label>
                                <div class="col-md-1 sm-d-none">:</div>
                                <div class="col-md-5">
                                    <label>{{priceData?.hotWalletBalance}}</label>
                                </div>
                            </div>
                            <div class="form-group row align-items-baseline">
                                <label class="col-md-6">Current Per token Price(USD)</label>
                                <div class="col-md-1 sm-d-none">:</div>
                                <div class="col-md-5">
                                    <label class="user-eml">{{priceDataa?.priceInUsd ||
                                        priceDataa?.marketPriceInUsd}}</label>
                                </div>
                            </div>
                            <div class="form-group row ">
                                <label class="col-md-6" style="margin-top: 8px;">Change Per token Price(USD)</label>
                                <div class="col-md-1 sm-d-none" style="margin-top: 8px;">:</div>
                                <div class="col-md-5">
                                    <input type="text" class="form-control" formControlName="price"
                                        placeholder="Enter Amount (USD)">
                                </div>
                            </div>

                        </div>
                        <div class="text-left mt40 text-center">
                            <button class="btn btn-large  max-WT-200 font-100 btn-green" [disabled]="!priceForm?.valid"
                                (click)="Change()">Change</button>
                        </div>
                    </div>
                </form>
            </div>
            <div class="order-view mt30 max-WT-700 mrgn-0-auto">
                <div class="tab-pane1">
                    <div class="table-responsive">
                        <table class="table table-bordered" aria-describedby="static content list">
                            <thead>
                                <tr class="no_wrap_th">
                                    <th scope="col">Toga Id</th>
                                    <th scope="col">Amount </th>
                                    <th scope="col" class="action_td_btn3">Type</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let data of slaeList, let i = index">
                                    <td>{{data?.togaId}}</td>
                                    <td>{{data?.togaBalance}}</td>
                                    <td> <span class="inn1"><input type="radio" name="same"
                                                (input)="updateBalance(data?.saleName, data?.togaBalance, data?.togaId)"
                                                [checked]=" ( data?.isStart == true) ? 'checked' : '' "></span>
                                        <span class="inn2">{{data?.saleName}}</span>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>

        <!-- tab price end -->

        <!-- tab user transaction buy start -->
        <div class="tab-pane active show" *ngIf="mytab && this.currTab=='User'">
            <div class="table-responsive">
                <table class="table table-bordered" style="width: 140%;line-height: 5px;" aria-describedby="user list">
                    <thead style="line-height: 5px;">
                        <tr class="no_wrap_th">
                            <th scope="col">S.No</th>
                            <th scope="col">Transaction ID</th>
                            <th scope="col">Transaction Type</th>
                            <th scope="col">Amount</th>
                            <th scope="col">Executable Coin</th>
                            <th scope="col">Executable Coin Amount</th>
                            <th scope="col">Date and Time</th>

                        </tr>
                    </thead>
                    <tbody style="text-align: center;  line-height: 5px;">
                        <tr
                            *ngFor="let data of buyTransactionData | paginate:{itemsPerPage:10, currentPage:pageNumber, totalItems:transactionLength};let i=index">
                            <td>{{10*(this.pageNumber-1)+i+1}}</td>
                            <td>{{data?.basicTradeHistoryId}}</td>
                            <td>{{data?.orderType}}</td>
                            <td>{{data?.baseCoinMarketPrice}}</td>
                            <td>{{data?.execCoinName}}</td>
                            <td>{{data?.execCoinamount}}</td>
                            <td>{{data?.creationTime | date: 'medium'}}</td>


                        </tr>
                        <tr *ngIf="buyTransactionData?.length==0">
                            <td colspan="10" vertical-align="middle">
                                <div class="no-record">
                                    <div class="no-recordin">
                                        <H5 style="margin-left: 10%;">No record found</H5>
                                    </div>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="custom-pagination mt20 " style="display: flex; justify-content:flex-end"
                *ngIf="transactionLength>0">
                <pagination-controls (pageChange)="pageNumber = $event"></pagination-controls>
            </div>
        </div>
        <!-- tab transaction  buy end -->

        <!-- tab user transaction withdraw start -->
        <div class="tab-pane active show" *ngIf="this.tab =='Withdraw' && this.currTab=='User'">
            <div class="table-responsive">
                <table style="width:140%; line-height: 5px;" class="table table-bordered" aria-describedby="user list">
                    <thead style="line-height: 5px;">
                        <tr class="no_wrap_th">
                            <th scope="col">S.No</th>
                            <th scope="col">User Name</th>
                            <th scope="col">User Email</th>
                            <th scope="col">Transaction ID</th>
                            <th scope="col">Transaction Type</th>
                            <th scope="col">Amount</th>
                            <th scope="col">Transaction Hash</th>
                            <th scope="col" style="width: 7%;">Date and Time</th>

                        </tr>
                    </thead>
                    <tbody>
                        <tr
                            *ngFor="let data of withdrawTransactionData | paginate:{itemsPerPage:10, currentPage:pageNumber, totalItems:transactionLength};let i=index">
                            <td>{{10*(this.pageNumber-1)+i+1}}</td>
                            <td style="white-space: nowrap">{{data?.userName}}</td>
                            <td>{{data?.userEmail}}</td>
                            <td>{{data?.txnId}}</td>
                            <td>{{data?.txnType}}</td>
                            <td>{{data?.amount}}</td>
                            <td> {{data?.txnHash == null ? 'Transaction Bought' : data?.txnHash }}</td>
                            <td>{{data?.txnTime | date: 'medium'}}</td>


                        </tr>
                        <tr *ngIf="withdrawTransactionData?.length==0">
                            <td colspan="8" vertical-align="middle">
                                <div class="no-record">
                                    <div class="no-recordin" style="text-align: center;">
                                        <h5>No record found</h5>
                                    </div>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="custom-pagination mt20 " style="display: flex; justify-content:flex-end"
                *ngIf="transactionLength>0">
                <pagination-controls (pageChange)="pageNumber = $event"></pagination-controls>
            </div>
        </div>
        <!-- tab transaction  withdraw end -->

        <!-- tab admin transfer start -->
        <div class="tab-pane active show" *ngIf="this.currTab == 'Transfer'">
            <div class="order-view mt30 max-WT-700 mrgn-0-auto">
                <form [formGroup]="transferForm">
                    <div class="main-block-innner mb40 mt40">
                        <div class="add-store-block input-style">

                            <div class="form-group row align-items-baseline">
                                <label class="col-md-6">Total Balance</label>
                                <div class="col-md-1 sm-d-none">:</div>
                                <div class="col-md-5">
                                    <label>{{priceData?.hotWalletBalance || 0}}</label>
                                </div>
                            </div>
                            <div class="form-group row align-items-baseline">
                                <label class="col-md-6">Coin Type</label>
                                <div class="col-md-1 sm-d-none">:</div>
                                <div class="col-md-5">
                                    <input type="text" readonly class="form-control" value="CRED"
                                        placeholder="Enter coin">
                                </div>
                            </div>
                            <div class="form-group row ">
                                <label class="col-md-6">Amount</label>
                                <div class="col-md-1 sm-d-none">:</div>
                                <div class="col-md-5">
                                    <input type="tel" class="form-control" formControlName="price"
                                        placeholder="Enter Amount">
                                </div>
                            </div>
                            <div class="form-group row ">
                                <label class="col-md-6">Recipent Address</label>
                                <div class="col-md-1 sm-d-none">:</div>
                                <div class="col-md-5">
                                    <input type="text" class="form-control" formControlName="address"
                                        placeholder="Enter Address">
                                </div>
                            </div>

                        </div>
                        <div class="text-left mt40 text-center">
                            <button class="btn btn-large  max-WT-200 font-100 btn-green"
                                [disabled]="!transferForm?.valid" (click)="transfer()">Transfer</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>

        <!-- tab tab admin transfer end -->
        <!-- tab admin transaction transfer start -->
        <div class="tab-pane active show" *ngIf="this.currTab == 'History'">
            <!-- <div class="user-main-head sec_head_new sec_head_new_after">
                <form [formGroup]="coinTokenForm">
                    <div class="row form-group">
                        <div class="col-md-3">
                            <div class="serch-boxuser">
                                <div class="input-group filter_search_group">
                                    <select formControlName="coinNameToken" class="form-control form1">
                                        <option value="">Select Coin</option>
                                        <option value="BTC">BTC</option>
                                        <option value="ETH">ETH</option>
                                        <option value="USDT">USDT</option>
                                        <option value="BNB">BNB</option>

                                    </select>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-2 col-sm-6 user-lst">
                            <div class="two-btnline">
                                <div class="text-left" style="margin-left: 1%" (click)="search()">
                                    <button type="submit" class="btn  btn-theme cus-mr">Search</button>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-2 col-sm-6 user-lst">
                            <div class="two-btnline sm-left">
                                <div class="text-left" style="margin-left: 5px" (click)="reset()">
                                    <button type="submit" class="btn  btn-theme cus-mr">Reset</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div> -->
            <div class="custom_tabs common-tabs">
                <div class="row mb20 justify-content-end">
                    <div class="col-md-12">
                        <!-- <select class="form-control"  (change)="adminTransfer($event.target.value)">
                            <option value="">ALL</option>
                            <option value="AVT">FIAT</option>
                            <option value="USDT">CRYPTO</option>

                        </select> -->
                        <ul class="nav nav-tabs d-flex  justify-content-center text-center">
                            <li class="nav-item flex-fill"> <a class="nav-link active show"
                                    [ngClass]="{'active': tabCoin=='All'}" (click)="coinSelectHistory('All')"
                                    href="javascript:;">ALL</a>
                            </li>
                            <li class="nav-item flex-fill"> <a class="nav-link active show"
                                    [ngClass]="{'active': tabCoin=='Fiat'}" (click)="coinSelectHistory('Fiat')"
                                    href="javascript:;">FIAT</a>
                            </li>
                            <li class="nav-item flex-fill"> <a class="nav-link active show"
                                    [ngClass]="{'active': tabCoin=='Crypto'}" (click)="coinSelectHistory('Crypto')"
                                    href="javascript:;">CRYPTO</a>
                            </li>

                        </ul>
                    </div>
                </div>
            </div>

            <div *ngIf="this.tabCoin=='All'">
                <div class="table-responsive">
                    <table class="table table-bordered" style="width: 110%;line-height: 5px;"
                        aria-describedby="user list">
                        <thead style="line-height: 5px;">
                            <tr class="no_wrap_th">
                                <th scope="col">S.No</th>

                                <th scope="col">Transaction ID <i class="fa fa-sort"
                                        (click)="sortAgent('txnId');isAssending=!isAssending" aria-hidden="true"
                                        style="cursor: pointer;"></i></th>
                                <th scope="col">Transaction Type <i class="fa fa-sort"
                                        (click)="sortAgent('txnType');isAssending=!isAssending" aria-hidden="true"
                                        style="cursor: pointer;"></i></th>
                                <th scope="col">Amount <i class="fa fa-sort"
                                        (click)="sortAgent('amount');isAssending=!isAssending" aria-hidden="true"
                                        style="cursor: pointer;"></i></th>
                                <th scope="col">Transaction Hash <i class="fa fa-sort"
                                        (click)="sortAgent('txnHash');isAssending=!isAssending" aria-hidden="true"
                                        style="cursor: pointer;"></i></th>
                                <th scope="col" style="width: 7%;">Date and Time <i class="fa fa-sort"
                                        (click)="sortAgent('txnTime');isAssending=!isAssending" aria-hidden="true"
                                        style="cursor: pointer;"></i></th>

                            </tr>
                        </thead>
                        <tbody *ngIf="transferData?.length">
                            <!-- <tr *ngFor="let data of transferData | paginate:{itemsPerPage:10, currentPage:pageNumber, totalItems:transactionLength};let i=index"> -->
                            <tr
                                *ngFor="let data of transferData | paginate:{itemsPerPage: pageSize, currentPage: pageNumber,totalItems:transferAll}; let i=index; ">

                                <td>{{10*(this.pageNumber-1)+i+1}}</td>

                                <td>{{data?.txnId}}</td>
                                <td>{{data?.txnType}}</td>
                                <td>{{data?.amount}}</td>
                                <!-- <td> <a target="blank" href="https://ropsten.etherscan.io/tx/{{data?.txnHash}}">{{data?.txnHash }}</a> </td> -->
                                <td> <a target="blank"
                                        href="https://ropsten.etherscan.io/tx/{{data?.txnHash}}">{{data?.txnHash == null
                                        ? 'Transaction Bought' : data?.txnHash }}</a> </td>
                                <td style="line-height: 15px;">{{data?.txnTime | date: 'medium'}}</td>
                            </tr>
                            <tr *ngIf="transferData?.length==0">
                                <td colspan="10" vertical-align="middle">
                                    <div class="no-record">
                                        <div class="no-recordin" style="text-align: center;">
                                            <h5>No record found</h5>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="custom-pagination mt20 " style="display: flex; justify-content:flex-end"
                    *ngIf="transferAll > 10 ">
                    <pagination-controls (pageChange)="pagination($event)"></pagination-controls>
                </div>
            </div>

            <div *ngIf="this.tabCoin=='Fiat'">
                <div class="table-responsive">
                    <table class="table table-bordered" style="width: 110%;line-height: 5px;"
                        aria-describedby="user list">
                        <thead style="line-height: 5px;">
                            <tr class="no_wrap_th">
                                <th scope="col">S.No</th>

                                <th scope="col">Transaction ID <i class="fa fa-sort"
                                        (click)="sortFiat('txnId');isAssending=!isAssending" aria-hidden="true"
                                        style="cursor: pointer;"></i></th>
                                <th scope="col">Transaction Type <i class="fa fa-sort"
                                        (click)="sortFiat('txnType');isAssending=!isAssending" aria-hidden="true"
                                        style="cursor: pointer;"></i></th>
                                <th scope="col">Amount <i class="fa fa-sort"
                                        (click)="sortFiat('amount');isAssending=!isAssending" aria-hidden="true"
                                        style="cursor: pointer;"></i></th>
                                <th scope="col">Transaction Hash <i class="fa fa-sort"
                                        (click)="sortFiat('txnHash');isAssending=!isAssending" aria-hidden="true"
                                        style="cursor: pointer;"></i></th>
                                <th scope="col" style="width: 7%;">Date and Time <i class="fa fa-sort"
                                        (click)="sortFiat('txnTime');isAssending=!isAssending" aria-hidden="true"
                                        style="cursor: pointer;"></i></th>

                            </tr>
                        </thead>
                        <tbody>
                            <tr
                                *ngFor="let data of transferDataAdd | paginate:{itemsPerPage:10, currentPage:pageNumbers, totalItems:transferFiat};let i=index">
                                <td>{{10*(this.pageNumbers-1)+i+1}}</td>

                                <td>{{data?.txnId}}</td>
                                <td>{{data?.txnType == 'AVT_TRANSFER' ? 'CRED TRANSFER' : data?.txnType}}</td>
                                <td>{{data?.amount}}</td>
                                <td> <a target="blank"
                                        href="https://ropsten.etherscan.io/tx/{{data?.txnHash}}">{{data?.txnHash == null
                                        ? 'Transaction Bought' : data?.txnHash }}</a> </td>
                                <td style="line-height: 15px;">{{data?.txnTime | date: 'medium'}}</td>
                            </tr>
                            <tr *ngIf="transferDataAdd?.length==0">
                                <td colspan="10" vertical-align="middle">
                                    <div class="no-record">
                                        <div class="no-recordin" style="text-align: center;">
                                            <h5>No record found</h5>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="custom-pagination mt20 " style="display: flex; justify-content:flex-end"
                    *ngIf="transferFiat > 10 ">
                    <pagination-controls (pageChange)="paginationFiat($event)"></pagination-controls>
                </div>
            </div>
            <div *ngIf="this.tabCoin=='Crypto'">
                <div class="user-main-head sec_head_new sec_head_new_after">

                    <!-- <p
                        style="margin-top: -3%;font-size: 124%;background-color: white; width: 10%; padding-left: 1%;">
                        Filter By</p> -->
                    <form [formGroup]="coinTokenForm">
                        <div class="row form-group">
                            <!-- <span style=" margin-top: 1%">Search By</span> -->

                            <!-- <div class="col-md-1" style="padding-top: 2%;">
                                <label for="" style="white-space: nowrap">Search By:</label>
                            </div> -->
                            <!-- <div class="col-md-2" style="padding-top: 1%;">
                                <select formControlName="status" class="form-control form1">
                                    <option value="">Select status</option>
                                    <option>PENDING</option>
                                    <option>ACCEPTED</option>
                                    <option>REJECTED</option>
                                </select>
                            </div> -->
                            <div class="col-md-3">
                                <div class="serch-boxuser">
                                    <div class="input-group filter_search_group">
                                        <select formControlName="coinNameToken" class="form-control form1">
                                            <option value="">Select Coin</option>
                                            <option value="BTC">BTC</option>
                                            <option value="ETH">ETH</option>
                                            <option value="USDT">USDT</option>
                                            <option value="BNB">BNB</option>

                                        </select>
                                    </div>
                                </div>
                            </div>

                            <!-- <div class="col-md-3 col-sm-12 user-lst">
                                <div class="">
                                    <div class="text-left">
                                        <input type="text" placeholder="Username" class="form-control form "
                                            formControlName="userName">
                                    </div>
                                </div>
                            </div> -->


                            <div class="col-md-2 col-sm-6 user-lst">
                                <div class="two-btnline">
                                    <div class="text-left" style="margin-left: 1%" (click)="search()">
                                        <button type="submit" class="btn  btn-theme cus-mr">Search</button>
                                    </div>
                                </div>

                            </div>
                            <div class="col-md-2 col-sm-6 user-lst">

                                <div class="two-btnline sm-left">
                                    <div class="text-left" style="margin-left: 5px" (click)="reset()">
                                        <button type="submit" class="btn  btn-theme cus-mr">Reset</button>
                                    </div>
                                </div>
                            </div>


                            <!-- <div class="col-md-2 col-sm-12" >

                                <button type="submit"  class="btn  btn-theme " (click)="exportAsXLSX()">EXPORT AS EXCEL
                                </button>
                            </div> -->
                        </div>

                    </form>
                </div>
                <div class="table-responsive">
                    <table class="table table-bordered" style="width: 110%;line-height: 5px;"
                        aria-describedby="user list">
                        <thead style="line-height: 5px;">
                            <tr class="no_wrap_th">
                                <th scope="col">S.No</th>

                                <th scope="col">Transaction ID <i class="fa fa-sort"
                                        (click)="sortingCrypto('txnId');isAssending=!isAssending" aria-hidden="true"
                                        style="cursor: pointer;"></i></th>
                                <th scope="col">Transaction Type <i class="fa fa-sort"
                                        (click)="sortingCrypto('txnType');isAssending=!isAssending" aria-hidden="true"
                                        style="cursor: pointer;"></i></th>
                                <th scope="col">Amount <i class="fa fa-sort"
                                        (click)="sortingCrypto('amount');isAssending=!isAssending" aria-hidden="true"
                                        style="cursor: pointer;"></i></th>
                                <th scope="col">Transaction Hash <i class="fa fa-sort"
                                        (click)="sortingCrypto('txnHash');isAssending=!isAssending" aria-hidden="true"
                                        style="cursor: pointer;"></i></th>
                                <th scope="col" style="width: 7%;">Date and Time <i class="fa fa-sort"
                                        (click)="sortingCrypto('txnTime');isAssending=!isAssending" aria-hidden="true"
                                        style="cursor: pointer;"></i></th>

                            </tr>
                        </thead>
                        <tbody>
                            <tr
                                *ngFor="let data of transferDataCrypto | paginate:{itemsPerPage:10, currentPage:pageNumberss, totalItems:transferCrypto};let i=index">
                                <td>{{10*(this.pageNumberss-1)+i+1}}</td>

                                <td>{{data?.txnId}}</td>
                                <td>{{data?.txnType}}</td>
                                <td>{{data?.amount}}</td>
                                <td> <a target="blank"
                                        href="https://ropsten.etherscan.io/tx/{{data?.txnHash}}">{{data?.txnHash == null
                                        ? 'Transaction Bought' : data?.txnHash }}</a> </td>
                                <td style="line-height: 15px;">{{data?.txnTime | date: 'medium'}}</td>
                            </tr>
                            <tr *ngIf="transferDataCrypto?.length==0">
                                <td colspan="10" vertical-align="middle">
                                    <div class="no-record">
                                        <div class="no-recordin" style="text-align: center;">
                                            <h5>No record found</h5>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="custom-pagination mt20 " style="display: flex; justify-content:flex-end"
                    *ngIf="transferCrypto > 5 ">
                    <pagination-controls (pageChange)="pagination($event)"></pagination-controls>
                </div>
            </div>
        </div>
        <!-- tab transaction  transfer end -->
    </div>
</main>