import { MainService } from './main.service';
import { Injectable } from '@angular/core';
import Web3 from "web3";
import { aib } from '../../assets/IERC20ABI.js'
import { addressABI } from '../../assets/address-inpt.js'
import { send } from 'process';
import { Contract } from "@ethersproject/contracts";
import { MM } from 'angular-mydatepicker';
import { BehaviorSubject } from 'rxjs';
declare const window: any;

@Injectable({
  providedIn: 'root'
})
export class ContractService {
  totalBalance = new BehaviorSubject(0);
  walletStatus = new BehaviorSubject(false);
  successMessage = new BehaviorSubject('');

  window: any;
  tokenAddress: any
  constructor(public service: MainService) { }

  // contactAddress = '0x29f4823dD0a4EebBB3565dB4fDE336E338F93E96'
  contactAddress = '0x728ebcec1436a6C7b17854C4B80DAFcc6860ED56'
  web3Infura = new Web3(Web3.givenProvider)

  private getAccounts = async () => {
    try {
      return await window.ethereum.request({ method: 'eth_accounts' });
    } catch (e) {
      return [];
    }
  }
  addresses: any
  token: any
  public openMetamask = async () => {
    window.web3 = new Web3(window.ethereum);
    this.addresses = await this.getAccounts();
    console.log("service", this.addresses)
    this.walletStatus.next(true)
    if (!this.addresses.length) {
      try {
        this.addresses = await window.ethereum.enable();

      } catch (e) {
        return false;
      }
    }
    return this.addresses.length ? this.addresses[0] : null;

  };
  balanceTotal: any

  async getBalance(address, contractAddress) {
    const web3: any = new Web3(window.ethereum);
    // let a = window.web3.eth.getBalance(address)
    //   .then((val) => console.log('balance ===>', val));
    // console.log(a);
    // console.log(aib);

    const IERC20Abi = aib; // JSON ABI of the token contract
    // const contractAddress = "0x123"; // address of the token contract
    // const tokenAddress = "0xfF3d"; // address of which you want to get the token balance

    this.token = new window.web3.eth.Contract(IERC20Abi, contractAddress);
    console.log(this.token);

    const balance = await this.token.methods.balanceOf(address).call();
    console.log(web3.utils.fromWei(balance));
    this.balanceTotal = web3.utils.fromWei(balance)
    this.totalBalance.next(this.balanceTotal)

    // token.methods.transfer("0xE24E0b643cc55eE9b9093FF3DE3054CE951Be2B7", 1).send({ from: address }, function (error, transactionHash) {
    //   console.log(transactionHash);
    //   console.log(address);

    // })

  }





  async sendBalance(address, multiAddress, tokenContrctAddress) {
    this.service.showSpinner()
    try {

      const web3: any = new Web3(window.ethereum);
      let tempAddress = String(multiAddress).split(',')

      let addresess = []
      let uni = []
      for (let item of tempAddress) {

        let amount = String(item).split('=')[1].trim()
        let add = String(item).split('=')[0].trim()
        addresess.push(add)
        let amount_in = web3.utils.toWei(amount)
        console.log(amount_in);
        uni.push(amount_in)

      }

      let price
      for (let i = 0; i < uni.length; i++) {
        price = price + uni[i]
      }
      console.log(price);
      console.log(address);




      // addresess.forEach((ele, index) => {
      //   this.token.methods.transfer(addresess[index], uni[index]).send({ from: address }, function (error, transactionHash) {

      //   })
      // })

      const IERC20Abi = aib; // JSON ABI of the token contract
      const MultiContractABI = addressABI; // JSON ABI of the multisender contract


      const tokenObject = new window.web3.eth.Contract(IERC20Abi, tokenContrctAddress);
      const MultiSenderObject = new window.web3.eth.Contract(MultiContractABI, this.contactAddress);

      // const signature = await web3.eth.sign(message, address);
      // console.log("===============signature", signature);

      // recover
      // const recoveredSigner = web3.eth.accounts.recover(message, signature);
      // console.log("===============recoveredSigner", recoveredSigner);

      console.log("===============119", addresess);
      console.log("===============120>", uni);
      // const distributeETH1 = await token.methods.distributeToken(this.contactAddress, addresess, uni).send({ from: address })

      //   console.log(distributeETH1, "distributdistributeETH1eETH");
      const allowanceallowance = await tokenObject.methods.allowance(
        address,
        this.contactAddress
      ).call();  // . aval

      console.log(allowanceallowance.toString());

      if (parseInt(allowanceallowance.toString()) == 0) {

        console.log(address, "this.contactAddress", this.contactAddress);
        console.log("jdsjdjdjjdjjjddjjd",)
        const setAllowanceallowance = await tokenObject.methods.approve(
          this.contactAddress,
          "115792089237316195423570985008687907853269984665640564039457584007913129639935"
        ).send({ from: address }); // . aval
        // await setAllowanceallowance.wait();
        // console.log("fdfdfsdfds", address);
        // console.log("tokenContrctAddress", tokenContrctAddress);
        // console.log("addresess", addresess);
        // console.log("uni", uni);


        const distributeETH1 = await MultiSenderObject.methods.distributeToken(tokenContrctAddress, addresess, uni).send({ from: address })

        console.log(distributeETH1, "distributdistributeETH1eETH"); // .   returnMessage
        this.successMessage.next('Transaction Successful')
        this.service.toasterSucc('Transaction Successful')
        this.service.hideSpinner()

      } else {
        // const setAllowanceallowance = await tokenObject.methods.approve(
        //   this.contactAddress,
        //   "115792089237316195423570985008687907853269984665640564039457584007913129639935"
        // ).send({ from: address }); // . aval
        // await setAllowanceallowance.wait();
        // console.log("wait", setAllowanceallowance);
        // console.log("fdfdfsdfds", address);
        // console.log("tokenContrctAddress", tokenContrctAddress);
        // console.log("addresess", addresess);
        // console.log("uni", uni);

        const distributeCredToken = await MultiSenderObject.methods.distributeToken(tokenContrctAddress, addresess, uni).send({ from: address })


        console.log(distributeCredToken, "distributeCredToken"); // returnMessage
        this.successMessage.next('Transaction Successful')
        this.service.toasterSucc('Transaction Successful')
        this.service.hideSpinner()

        // await distributeCredToken.wait();

        // const balance = await MultiSenderObject.methods.distributeToken(address, addresess, uni).send();
        // console.log(web3.utils.fromWei(balance));

      }



    } catch (error) {
      console.log("error:--", error);
      this.service.toasterErr('Transaction Failed')
    }

  }

}
