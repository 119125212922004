<main class="middle-content">
    <div class="content-section">
        <div class="page_title_block pL0 pR0">
            <h1 class="page_title" style="color:#26272d">REFERRAL VIEW </h1>
        </div>

        <!-- tab General -->

        <div class="tab-pane active show">
            <div class="order-view mt30 max-WT-700 mrgn-0-auto">
                <h6 class="page_title" style="color:#26272d;">Referred From </h6>
                <div class="main-block-innner mb40 mt40">
                    <div class="add-store-block input-style">
                        <div class="form-group row align-items-baseline">
                            <label class="col-md-4">Username</label>
                            <div class="col-md-1 sm-d-none">:</div>
                            <div class="col-md-7">
                                <label>{{usersDetils?.firstName}}</label>
                            </div>
                        </div>
                        <div class="form-group row align-items-baseline">
                            <label class="col-md-4">Email</label>
                            <div class="col-md-1 sm-d-none">:</div>
                            <div class="col-md-7">
                                <label>{{usersDetils?.email}}</label>
                            </div>
                        </div>
                        <div class="form-group row align-items-baseline">
                            <label class="col-md-4">Phone No</label>
                            <div class="col-md-1 sm-d-none">:</div>
                            <div class="col-md-7">
                                <label>{{usersDetils?.phoneNo}}</label>
                            </div>
                        </div>
                        <div class="form-group row align-items-baseline">
                            <label class="col-md-4">Address</label>
                            <div class="col-md-1 sm-d-none">:</div>
                            <div class="col-md-7">
                                <label>{{usersDetils?.address}}</label>
                            </div>
                        </div>
                    </div>

                    <!-- referred to -->
                    <h6 class="page_title" style="color:#26272d; margin-bottom: 22px; margin-top: 32px;">Referred To
                    </h6>
                    <div class="add-store-block input-style">
                        <div class="form-group row align-items-baseline">
                            <label class="col-md-4">Username</label>
                            <div class="col-md-1 sm-d-none">:</div>
                            <div class="col-md-7">
                                <label>{{usersData?.firstName || 'N/A'}}</label>
                            </div>
                        </div>
                        <div class="form-group row align-items-baseline">
                            <label class="col-md-4">Email</label>
                            <div class="col-md-1 sm-d-none">:</div>
                            <div class="col-md-7">
                                <label>{{usersData?.email || 'N/A'}}</label>
                            </div>
                        </div>
                        <div class="form-group row align-items-baseline">
                            <label class="col-md-4">Phone No</label>
                            <div class="col-md-1 sm-d-none">:</div>
                            <div class="col-md-7">
                                <label>{{usersData?.phoneNo || 'N/A'}}</label>
                            </div>
                        </div>
                        <div class="form-group row align-items-baseline">
                            <label class="col-md-4">Address</label>
                            <div class="col-md-1 sm-d-none">:</div>
                            <div class="col-md-7">
                                <label>{{usersData?.address || 'N/A'}}</label>
                            </div>
                        </div>

                    </div>

                    <div class="text-left mt40 text-center">
                        <a routerLink="/referral" class="btn btn-large  max-WT-200 font-100 btn-green">Back</a>
                        <!-- <a (click)="reply()" class="btn btn-large  max-WT-200 font-100 btn-green ml-2">Reply</a> -->
                    </div>
                </div>
            </div>
        </div>

        <!-- tab genral end -->


        <!-- tab login session end -->

    </div>
</main>
<footer style="margin-top: 8%;">
    <div class="container-fluid">
        <p class="copyright">Blockchain Project Development <strong class="theme-text-color"></strong>
        </p>
    </div>
</footer>