<main class="middle-content">
    <div class="content-section">
        <div class="page_title_block pL0 pR0">
            <h1 class="page_title" style="color:#26272d">TOKEN</h1>
        </div>

        <!-- tab General -->
        <div class="tab-pane active show">
            <div class="order-view mt30 max-WT-1050 mrgn-0-auto">
                <div class="main-block-innner mb40 mt40">
                    <div class="mt-3" style="text-align: end;">
                        <!-- <button class="btn max-WT-200 font-100 btn-green enableEthereumButton"
                            onclick="connectWallet()">Connect Wallet</button> -->
                        <button *ngIf="!status" class="btn max-WT-200 font-100 btn-green enableEthereumButton"
                            (click)="openMetaMask()">Connect Wallet</button>
                        <button *ngIf="status"
                            class="btn max-WT-200 font-100 btn-green enableEthereumButton">Connected</button>
                    </div>
                    <h4 class="head">Token Address</h4>
                    <div>
                        <input type="text" [(ngModel)]="tokenAddress" placeholder="address" class="form-control form ">
                    </div>
                    <p class="mt-1" style="text-align: end;" *ngIf="balance">Total Balance: {{toFixed(balance,3)}}</p>
                    <div class="mt-3">
                        <button class="btn max-WT-200 font-100 btn-green" (click)="getBalance()"
                            [disabled]="!tokenAddress  || status == false">Get Balance</button>
                        <!-- <button class="btn max-WT-200 font-100 btn-green" (click)="getBalance()"
                            [disabled]="(!tokenAddress && status == false)">Get Balance</button> -->
                    </div>
                </div>
                <div class="main-block-innner mb40 mt40">
                    <h4 class="head">Address</h4>
                    <div>
                        <p>Enter Addresses (Comma-separated).</p>
                        <input style="height: 70px;" type="text" [(ngModel)]="multiAddress" placeholder="address"
                            class="form-control form ">
                    </div>
                    <div class="mt-3">
                        <button class="btn max-WT-200 font-100 btn-green" (click)="sendBalance()"
                            [disabled]="!multiAddress  || status == false || !tokenAddress">Send</button>
                    </div>
                </div>

            </div>
        </div>


    </div>
</main>
<footer style="margin-top: 8%;">
    <div class="container-fluid">
        <p class="copyright">Blockchain Project Development <strong class="theme-text-color"></strong>
        </p>
    </div>
</footer>