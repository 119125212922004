<main class="middle-content">
    <div class="page_title_block">
        <h1 class="page_title" style="color:#26272d">ALL USER TRADES</h1>
    </div>

    <div class="content-section">
        <div class="outer-box">
            <div class="global-table no-radius p0">
                <div class="tab-content1">
                    <div class="tab-pane1">

                        <form [formGroup]="traderForm" class="all-form">
                            <div class="row form-group" style="width: 100%;">
                                <div class="col-md-4">
                                    <div class="serch-boxuser">
                                        <div class="input-group filter_search_group">
                                            <input type="text" class="form-control" placeholder="Search by email"
                                                formControlName="searchText">
                                            <div class="input-group-append">
                                                <button class="btn btn_search_group" type="button"><img
                                                        src="assets/img/icon-search.png" alt="Search"></button>
                                            </div>

                                        </div>

                                    </div>

                                </div>

                                <div class="col-md-4 user-lst text-left">
                                    <div class="two-btnline text-left">
                                        <div class="text-left">
                                            <button type="submit" class="btn  btn-theme cus-mr"
                                                (click)="search()">Search</button>
                                        </div>
                                        <div class="head_flt_select">
                                            <div class="head_flt_select input-label" style="margin-left: 1%">
                                                <button type="submit" class="btn  btn-theme" (click)="reset()">Reset
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="row  d-flex justify-content-end w_100">

                                    <button type="submit" class="btn  btn-theme " (click)="exportAsXLSX()">EXPORT AS
                                        EXCEL
                                    </button>
                                </div>
                            </div>
                        </form>


                        <div class="table-responsive">
                            <table class="table table-bordered" aria-describedby="user list">
                                <thead>
                                    <tr class="no_wrap_th">
                                        <th scope="col">S.No</th>
                                        <th scope="col">Transaction ID </th>
                                        <th scope="col">Transaction Type</th>
                                        <th scope="col" style="width: 7%;">User Email </th>
                                        <th scope="col">Base Coin Amount</th>
                                        <th scope="col">Exec Coin Amount</th>
                                        <th scope="col">Base Coin</th>
                                        <th scope="col">Executable Coin</th>
                                        <th scope="col">Date and Time</th>
                                        <th scope="col" class="action_td_btn3">Status</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr
                                        *ngFor="let data of traderData | paginate:{itemsPerPage:10, currentPage:pageNumber, totalItems:length}; let i=index">
                                        <td>{{10*(this.pageNumber-1)+i+1}}</td>
                                        <td>{{data?.basicTradeHistoryId}}</td>
                                        <td>{{data?.orderType}}</td>
                                        <td>{{data?.userEmail}}</td>
                                        <td>{{data?.baseCoinAmmount}}</td>
                                        <td>{{data?.execCoinamount}}</td>
                                        <td>{{data?.baseCoinName}}</td>
                                        <td>{{data?.execCoinName}}</td>
                                        <td>{{data?.creationTime | date: 'medium'}}</td>
                                        <td>{{data?.status }}
                                        </td>
                                    </tr>
                                    <tr *ngIf="traderData?.length==0">
                                        <td colspan="10" vertical-align="middle">
                                            <div class="no-record">
                                                <div class="no-recordin">
                                                    <H5 style="margin-left: 10%;">No record found</H5>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div class="custom-pagination mt20 " style="display: flex; justify-content:flex-end">
                            <pagination-controls (pageChange)="pageNumber=$event"></pagination-controls>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</main>

<footer>
    <div class="container-fluid">
        <p class="copyright">CRED Development© 2022 All Rights Reserved. <strong class="theme-text-color"></strong>
        </p>
    </div>
</footer>

<!-- active Start -->
<div class="modal fade global-modal reset-modal" id="active">
    <div class="modal-dialog max-WT-500">
        <form class="change_password">
            <div class="modal-content">
                <div>
                    <div class="modal-body">
                        <h3 style="text-align: center; ">Active User </h3>
                        <hr>
                        <button type="button" class="close" data-dismiss="modal"
                            style="margin-top: -85px;">&times;</button>
                        <div class="text-center modal_flax_height d-flex align-items-center justify-content-center">
                            <div class="w-100">
                                <p style="font-size: 25px;">Are you sure you want to active this user?</p>
                                <div>
                                    <button type="submit" class="btn btn-success mr-2">Yes</button>
                                    <button type="button" class="btn btn-danger" data-dismiss="modal">No</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>