<main class="middle-content">
    <div class="content-section">
        <div class="page_title_block pL0 pR0">
            <h1 class="page_title" style="color:#26272d">VIEW FIAT </h1>
        </div>
        <div class="tab-pane active show">
            <div class="order-view mt30 max-WT-700 mrgn-0-auto">
                <div class="main-block-innner mb40 mt40" style="text-align: justify;
                margin-left: 11%;">
                    <div class="add-store-block input-style">

                        <div class="form-group row">
                            <label class="col-md-5 text" for="">Token Name</label>
                            <span class="col-md-1">:</span>
                            <div class="col-md-6">
                                <p>{{viewData?.tokenName || 'N/A'}}</p>
                            </div>
                        </div>
                        <div class="form-group row">
                            <label class="col-md-5 text" for="">Bank Name</label>
                            <span class="col-md-1">:</span>
                            <div class="col-md-6">
                                <p>{{viewData?.bankName || 'N/A'}}</p>
                            </div>
                        </div>

                        <div class="form-group row">
                            <label class="col-md-5 text" for="">Account Holder Name</label>
                            <span class="col-md-1">:</span>
                            <div class="col-md-6">
                                <p>{{viewData?.accountHolderName || 'N/A'}}</p>
                            </div>

                        </div>
                        <div class="form-group row">
                            <label class="col-md-5 text" for="">Account Number</label>
                            <span class="col-md-1">:</span>
                            <div class="col-md-6">
                                <p>{{viewData?.accountNo || 'N/A'}}</p>
                            </div>
                        </div>
                        <div class="form-group row">
                            <label class="col-md-5 text" for="">Amount</label>
                            <span class="col-md-1">:</span>
                            <div class="col-md-6">
                                <p>{{viewData?.amount || 'N/A'}}</p>
                            </div>
                        </div>
                        <div class="form-group row">
                            <label class="col-md-5 text" for="">Status</label>
                            <span class="col-md-1">:</span>
                            <div class="col-md-6">
                                <p>{{viewData?.status || 'N/A'}}</p>
                            </div>
                        </div>




                        <div class="text-left mt40 " style="margin-left: 17%;">
                            <a routerLink="/fiat" class="btn btn-large  max-WT-200 font-100 btn-green">Back</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>


    </div>
</main>
<footer>
    <div class="container-fluid">
        <p class="copyright">CRED Development© 2022 All Rights Reserved. <strong class="theme-text-color"></strong>
        </p>
    </div>
</footer>