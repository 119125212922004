import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ContractService } from 'src/app/provider/contract.service';
import { MainService } from 'src/app/provider/main.service';
import Web3 from "web3";

@Component({
  selector: 'app-multisender-management',
  templateUrl: './multisender-management.component.html',
  styleUrls: ['./multisender-management.component.css']
})
export class MultisenderManagementComponent implements OnInit {

  id: number;
  ticketDetail: any;
  tokenAddress: any = ''
  multiAddress: any = ''
  balance: any;
  status: any;
  successMsg: any
  constructor(private route: ActivatedRoute, private router: Router, public service: MainService, public webService: ContractService) {
    this.webService.totalBalance.subscribe((res) => {
      this.balance = res
    });
    this.webService.walletStatus.subscribe((res) => {
      this.status = res;
      console.log(this.status);
    })
    this.webService.successMessage.subscribe((res) => {
      this.successMsg = res;
      console.log(this.successMsg);
    })
  }

  ngOnInit(): void {
    console.log(this.webService.balanceTotal);

  }
  address: any
  openMetaMask() {
    this.webService.openMetamask().then(resp => {
      console.log(resp);
      this.address = resp
    })
  }
  getBalance() {
    this.webService.getBalance(this.address, this.tokenAddress)
    console.log(this.balance);
  }
  sendBalance() {

    this.webService.sendBalance(this.address, this.multiAddress, this.tokenAddress)
  }

  toFixed(number, toLength: number) {
    return Number(number).toFixed(toLength)
  }


}
