import { MainService } from 'src/app/provider/main.service';
import { Component } from '@angular/core';
import { NavigationEnd, Router, Event } from '@angular/router';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'bit-coin-wallet-admin-panel';

  currUrl: any;
  view: boolean = false;
  constructor(public service: MainService, public routes: Router) {
    routes.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd) {
        this.currUrl = event.url.split('/')[1];
      }
      if (!(this.currUrl == `login` || this.currUrl == `forgot-password` || this.currUrl == `reset-password` || this.currUrl == ``)) {
        this.view = false
      } else {
        this.view = !this.view
      }
    })
  }

  ngOnInit() {


    //   $(".btn-toggle,.close_panel").click(function() {
    //     $("body").toggleClass("toggle-wrapper");
    // });
    // this.service.initSocketSupportChat() //
  }
}
