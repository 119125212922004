<main class="middle-content">
    <div class="content-section">
        <div class="page_title_block pL0 pR0">
            <h1 class="page_title" style="color:#26272d">Fee Management</h1>
        </div>
        <div class="custom_tabs common-tabs">
            <div class="row mb20 justify-content-center">
                <div class="col-sm-12">
                    <ul class="nav nav-tabs d-flex  justify-content-center text-center">
                        <li class="nav-item flex-fill"> <a class="nav-link active show"
                                [ngClass]="{'active': currTab=='MINIMUM_WITHDRAW'}"
                                (click)="selectTab('MINIMUM_WITHDRAW')" href="javascript:;">Minimum Withdrawal
                                Amount</a>
                        </li>
                        <li class="nav-item flex-fill"> <a class="nav-link active show"
                                [ngClass]="{'active': currTab=='WITHDRAW_FEE'}" (click)="selectTab('WITHDRAW_FEE')"
                                href="javascript:;">Withdrawal Fee</a>
                        </li>
                        <!-- <li class="nav-item flex-fill"> <a class="nav-link active show"
                                [ngClass]="{'active': currTab=='USD'}" (click)="selectTab('USD')"
                                href="javascript:;">USD Price</a>
                        </li> -->
                        <li class="nav-item flex-fill"> <a class="nav-link active show"
                                [ngClass]="{'active': currTab=='TDS'}" (click)="selectTab('TDS')"
                                href="javascript:;">TDS</a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>

        <div class="tab-pane active show" *ngIf="this.currTab == 'MINIMUM_WITHDRAW'">

            <app-min-withdrawl-amount></app-min-withdrawl-amount>
        </div>
        <div class="tab-pane active show" *ngIf="this.currTab == 'WITHDRAW_FEE'">

            <app-standard-trading-fee></app-standard-trading-fee>
        </div>
        <!-- <div class="tab-pane active show" *ngIf="this.currTab == 'USD'">
            <app-usd-price></app-usd-price>

        </div> -->
        <div class="tab-pane active show" *ngIf="this.currTab == 'TDS'">

            <div class="outer-box">
                <div class="global-table no-radius p0">
                    <div class="tab-content1">
                        <div class="tab-pane1">
                            <div class="table-responsive">
                                <table class="table table-bordered" aria-describedby="user list">
                                    <thead>
                                        <tr class="no_wrap_th">
                                            <th scope="col">S.No</th>
                                            <th scope="col">TDS</th>
                                            <th scope="col">Coin name</th>
                                            <th scope="col">Action</th>

                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr
                                            *ngFor="let item of coinList  | paginate:{itemsPerPage:10, currentPage:pageNumber, totalItems:coinList.length?.length}; let i=index">
                                            <td>{{i+1}}</td>
                                            <td *ngIf="!cointAmount[i]">{{item.tdsFee}}</td>
                                            <td *ngIf="cointAmount[i]">
                                                <input [value]="item.tdsFee"
                                                    [(ngModel)]="withdrawAmount[i] = item.tdsFee"
                                                    (keypress)="service.preventSpace($event)" style="text-align: left;"
                                                    maxlength="6" type="text" placeholder="TDS Fee"
                                                    class="form-control">
                                            </td>

                                            <td>{{item.coinShortName == 'AVT' ? 'CRED' : item.coinShortName}}</td>
                                            <td>
                                                <button *ngIf="!cointAmount[i]" type="submit"
                                                    class="btn  btn-theme cus-mr" (click)="enableInputField(i)"
                                                    style="width: 88%;">Edit</button>
                                                <button *ngIf="cointAmount[i]" type="submit"
                                                    class="btn  btn-theme cus-mr"
                                                    (click)="updateCoinAmount(item.coinShortName,withdrawAmount[i])"
                                                    style="width: 88%;">Update</button>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            <!-- </div>
            </main> -->
        </div>




    </div>
</main>