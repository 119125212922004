<!-- <body class="afterlogin bg-image">
    <div class="login-wrapper ">
        <div class="container-common">
            <div class="row justify-content-center ">
                <div class="col-md-6">
               
                    <form class="login_box_outer" [formGroup]="loginForm">
                        <div class="login-box max-WT-520">
                            <div class="login-right-block">
                                <div class="login-heading">
                                    <h4 style="color:#2d3498">LOGIN</h4>
                                </div>
                                <div class="login-box-body">
                                    <div class="form-group">
                                        <input type="email" class="form-control" placeholder="Email"
                                            formControlName="email" (keypress)="service.preventSpace($event)" />

                                        <div
                                            *ngIf="loginForm.get('email').hasError('required') && loginForm.get('email').touched">
                                            <span class="error">*Email is required.</span>
                                        </div>
                                        <span class="error">
                                            <p *ngIf="loginForm.get('email').hasError('pattern') && loginForm.get('email').dirty"
                                                class="error" padding>*Please enter valid email.</p>
                                        </span>
                                    </div>
                                    <div class="form-group">
                                        <input type="password" class="form-control " placeholder="Password"
                                            formControlName="password" (keypress)="service.preventSpace($event)" />

                                        <div
                                            *ngIf="loginForm.controls['password'].hasError('required') && loginForm.controls['password'].touched">
                                            <span class="error">*Password is required.</span>
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                        <div class="col-6">
                                            <div class="remember-text ">
                                                <label class="checkbox-design" style="color:#2d3498">
                                                    <input type="checkbox"
                                                        formControlName='rememberMe' /><span></span>Remember me
                                                </label>
                                            </div>
                                        </div>

                                        <div class="col-lg-6">
                                            <div class="forgot-links">
                                                <a [routerLink]="['/forgot-password']"
                                                    routerLinkActive="router-link-active" style="color:#2d3498">Forgot
                                                    Password?</a>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group text-center mt40">
                                        <button type="submit" style="background: #2d3498;width: 53%;"
                                            class="btn btn-login btn-large  width100 font-100" (click)="onLogin()"
                                            [disabled]="loginForm.invalid">LOGIN</button>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
    <hr />

    <footer class="footer1 ">
        <div class="container-fluid ">
            <p class="copyright">Blockchain Project Development <strong class="theme-text-color"></strong>
            </p>
        </div>
    </footer> -->

<div class="login-wrapper"
    style="background-image: url(assets/img/nearmall.png);background-size: cover;height: 100%;background-repeat: no-repeat;">
    <div class="container-common">
        <div class="row justify-content-center">
            <div class="col-md-6">
                <div class="d-flex align-items-center minheight70vh">
                    <form class="login_box_outer w-100" [formGroup]="loginForm">
                        <div class="login-box max-WT-520" style="opacity: 0.8;
                            background: rgba(0,0,1,0.8);
                        ">
                            <div class="login-right-block">
                                <div class="text-center">
                                    <img src="assets\img\logoo.png" alt="crypto-logo" class="data-img">

                                </div>
                                <div style="visibility: hidden;" class="login-heading">
                                    <h4>LOGIN</h4>
                                </div>
                                <div class="login-box-body">
                                    <div class="form-group">
                                        <input type="text" formControlName="email"
                                            (keypress)="service.preventSpace($event)" id="loginPhoneNumber"
                                            class="form-control" placeholder="Email"
                                            style="background:transparent ; color: #fff;" />
                                        <div class="text-danger"
                                            *ngIf="loginForm.get('email').hasError('required') && loginForm.get('email').touched">
                                            *Please enter email address.
                                        </div>
                                        <ng-template #check>
                                            <div class="text-danger"
                                                *ngIf="loginForm.get('email').hasError('pattern') && loginForm.get('email').dirty">
                                                *Please enter valid email address.
                                            </div>

                                        </ng-template>

                                    </div>
                                    <div class="form-group eyeicon">
                                        <input [type]="showPassOrText ? 'text': 'password'" maxlength="18"
                                            formControlName="password" (keypress)="service.preventSpace($event)"
                                            class="form-control" placeholder="Password"
                                            style="background:transparent ;color: #fff;" />
                                        <em [class]="showEyeOrCrossEye? 'fa fa-eye':'fa fa-eye-slash'"
                                            aria-hidden="true" (click)="showPassword()" style="color: gray;"></em>

                                        <div class="text-danger"
                                            *ngIf="loginForm.controls['password'].hasError('required') && loginForm.controls['password'].touched">
                                            *Please enter password.
                                        </div>
                                        <!--     <div class="text-danger"
                                                *ngIf="loginForm.get('password').hasError('minlength') && !(loginForm.get('password').hasError('pattern')) && loginForm.get('password').dirty">
                                                *Minimum length must be 8.
                                            </div>
                                            <div class="text-danger"
                                                *ngIf="loginForm.get('password').hasError('maxlength') && !(loginForm.get('password').hasError('pattern')) && loginForm.get('password').dirty">
                                                *Maximum length should not more than 16.
                                            </div>
                                            <div class="text-danger" *ngIf="loginForm.get('password').hasError('pattern')">
                                                *Password should have minimum 8 characters, at
                                                least 1 uppercase letter, 1 lowercase letter, 1
                                                number and 1 special character.
                                            </div> -->
                                        <!-- <div></div> -->
                                    </div>

                                    <div class="form-group row">
                                        <div class="col-lg-12">
                                            <div class="rememberme-section">
                                                <!-- <label class="checkbox-design" style="color:#fff;font-weight: 400;">
                                                        <input type="checkbox"  formControlName='rememberMe'  class="form-control"/><span></span>Remember me
                                                    </label> -->
                                                <label class="main">Remember me
                                                    <input type="checkbox" formControlName='rememberMe'
                                                        class="form-control">
                                                    <span class="geekmark"></span>
                                                </label>
                                                <label class="checkbox-design" style="color:#fff;font-weight: 400;">
                                                    <span [routerLink]="['/forgot-password']">Forgot Password ?</span>
                                                </label>

                                            </div>

                                        </div>
                                    </div>
                                </div>
                                <div class="text-center mt40">
                                    <button (click)="onLogin()" [disabled]="!loginForm.valid"
                                        class="btn btn-login btn-large  width100 font-600">LOGIN</button>
                                </div>

                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>