import { Component, OnInit } from "@angular/core";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { MainService } from "src/app/provider/main.service";
import { element } from "protractor";
import { Inject, LOCALE_ID, Pipe, PipeTransform } from "@angular/core";
import { DatePipe } from "@angular/common";
@Component({
  selector: "app-payment-list",
  templateUrl: "./payment-list.component.html",
  styleUrls: ["./payment-list.component.css"],
})
export class PaymentListComponent implements OnInit {
  kycForm: FormGroup;
  pageNumber: number = 1;
  paymentData: any = [];
  userId: any;
  url: string;
  totalRecords: any;
  // today = new Date().toISOString().split("T")[0];
  today: any = new Date().toISOString().slice(0, 16);

  isSearched: boolean = false;
  isAssending: boolean = true;

  constructor(
    public route: Router,
    public service: MainService,
    public transform: DatePipe
  ) { }

  ngOnInit(): void {
    this.kyc();
    this.kycForm = new FormGroup({
      status: new FormControl("", Validators.required),
      // 'userName': new FormControl('', Validators.required),
      searchUsername: new FormControl(""),
      enddate: new FormControl("", Validators.required),
      startdate: new FormControl("", Validators.required),
    });
  }

  // api of kyc listing
  kyc() {
    var url =
      "stripe/payment/get-payment-History?page=" +
      (this.pageNumber - 1) +
      "&pageSize=10";
    this.service.showSpinner();
    this.service.post(url, {}).subscribe(
      (res: any) => {
        console.log("kyc", res);
        if (res.status == 200) {
          this.paymentData = res.data.content;
        }
        this.totalRecords = res.data.totalElements;
        this.service.hideSpinner();
      },
      (err) => {
        if (err["status"] == 401) {
          this.service.hideSpinner();
          this.service.toasterErr("Unauthorized Access");
        } else {
          this.service.hideSpinner();
          this.service.toasterErr("Something Went Wrong");
        }
      }
    );
  }

  search() {
    let startdate = Date.parse(this.kycForm.value.startdate);
    let enddate = Date.parse(this.kycForm.value.enddate);
    let trxnId = this.kycForm.value.status;
    let searchText = this.kycForm.value.searchUsername;
    let emailRegex = "^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$";
    let isEmail = searchText.match(emailRegex);
    let url = `stripe/payment/get-all-payment-history-stripe?page=${this.pageNumber - 1}&pageSize=10${searchText ? (isEmail ? '&email=' + searchText : '&userName=' + searchText) : ''}${startdate ? '&fromDate=' + startdate : ''}${enddate ? '&toDate=' + enddate : ''}${trxnId ? '&txnId=' + trxnId : ''}`
    this.service.showSpinner();
    this.service.get(url).subscribe(
      (res: any) => {
        if (res.status == 200) {
          this.service.hideSpinner();
          this.paymentData = res.data.RESULT_LIST || res.data.emailPaymentDetails || res.data.dateDetails || res.data.content || [];
          // this.paymentData = res.data.content;

          this.totalRecords = res.data.totalCount;
          this.totalRecords = res.data.totalElements;
        }
        else {
          // alert('lodu')
          this.paymentData = [];
          this.service.hideSpinner();
          this.totalRecords = 0
        }

      },

      (err) => {
        this.service.hideSpinner();
        // this.listing = [];

        // this.totalRecords = err.data.totalCount;
      }
    );
  }


  reset() {
    if (
      this.kycForm.value.status ||
      this.kycForm.value.startdate ||
      this.kycForm.value.enddate ||
      this.kycForm.value.searchUsername
    ) {
      this.kycForm.reset({
        startdate: "",
        enddate: "",
        status: "",
        searchUsername: ''
      });
      this.kyc();
    }
  }
  // Api of search
  // search() {

  //   let kycStatus = this.kycForm.value.status;
  //   let search = this.kycForm.value.userName
  //   if (this.kycForm.value.userName && this.kycForm.value.status) {
  //     this.url = "account/admin/kyc-management/filter-kyc-users-list?page=" + (this.pageNumber - 1) + "&pageSize=10" + "&kycStatus=" + kycStatus + "&search=" + search;
  //   }
  //   else if (this.kycForm.value.userName) {
  //     this.url = "account/admin/kyc-management/filter-kyc-users-list?page=" + (this.pageNumber - 1) + "&pageSize=10" + "&search=" + search;
  //   }
  //   else if (this.kycForm.value.status) {
  //     this.url = "account/admin/kyc-management/filter-kyc-users-list?page=" + (this.pageNumber - 1) + "&pageSize=10" + "&kycStatus=" + kycStatus;
  //   }
  //   this.service.get(this.url).subscribe((res: any) => {
  //     console.log('search', res);

  //     if (res['status'] == 200) {
  //       this.paymentData = res.data.list;
  //       this.service.toasterSucc('User found successfully')

  //     }

  //   }, (err) => {
  //     if (err['status'] == 401) {
  //       this.service.toasterErr('Unauthorized Access')
  //     }
  //     else {
  //       this.service.toasterErr('Something Went Wrong');
  //     }
  //   })

  // }

  // reset

  view(id) {
    console.log("gg", id);
    this.route.navigate(["/payment-view"], { queryParams: { id: id } });
  }

  copyToClipboard(item) {

    this.service.toasterSucc('copied')
    document.addEventListener('copy', (e: ClipboardEvent) => {
      e.clipboardData.setData('text/plain', (item));
      e.preventDefault();
      document.removeEventListener('copy', null);
    });
    document.execCommand('copy');
  }

  copy(text) {
    navigator.clipboard.writeText(text);
    this.service.toasterSucc("Copy to clipboard");
  }

  exportAsXLSX() {
    let dataArr = [];
    this.paymentData.forEach((element, ind) => {
      dataArr.push({
        "S.No": ind + 1,
        "User Id": element.userId ? element.userId : "N/A",
        "Payment Id": element.paymentId ? element.paymentId : "N/A",
        "Card Number": element.nubmer ? element.nubmer : "N/A",
        Amount: element.amount ? element.amount : "N/A",
        Currency: element.currency ? element.currency : "N/A",
        "Customer Id": element.customer ? element.customer : "N/A",
        TxnId: element.txnId ? element.txnId : "N/A",
        "Card Id": element.cardId ? element.cardId : "N/A",
        "Payment Status": element.paymentStatus ? element.paymentStatus : "N/A",
        "Date & Time": element.createTime ? element.createTime : "N/A",
      });
    });
    console.log(dataArr);
    this.service.exportAsExcelFile(dataArr, "Payment List");
  }

  pagination(page) {
    // this.totalRecords = [];
    console.log("jh", page);
    this.pageNumber = page;
    console.log("jh", this.pageNumber);

    this.kyc();
  }
  sortAgent(key) {
    if (this.isAssending) {
      this.paymentData = this.paymentData.sort((a, b) => {
        return a[key] > b[key] ? 1 : -1;
      });
    } else {
      this.paymentData = this.paymentData.sort((a, b) => {
        return a[key] < b[key] ? 1 : -1;
      });
    }
  }
}
