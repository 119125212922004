import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MainService } from 'src/app/provider/main.service';

@Component({
  selector: 'app-referral-view',
  templateUrl: './referral-view.component.html',
  styleUrls: ['./referral-view.component.css']
})
export class ReferralViewComponent implements OnInit {

  userId: any;
  referredId: any
  ticketDetail: any;

  constructor(private route: ActivatedRoute, private router: Router, public service: MainService) {
    this.route.queryParams.subscribe((params) => {
      this.userId = params.userID;
      this.referredId = params.refId
    });
  }

  ngOnInit(): void {
    this.usersReferralIdView();
    this.usersReferralUserIDView();
    console.log(this.userId);
    console.log(this.referredId);

  }


  usersDetils: any
  usersReferralIdView() {
    let url = `account/admin/user-management/get-user-data-list?userData=${this.referredId}`
    this.service.get(url).subscribe((res: any) => {
      if (res['status'] == 200) {
        this.usersDetils = res['data']['data']['data']
        console.log(this.usersDetils);

        this.service.hideSpinner();
        this.service.toasterSucc(res.message);
      } else {
        this.service.hideSpinner();
        this.service.toasterErr(res.message)
      }
    }, (err) => {
      this.service.hideSpinner();
      this.service.toasterErr(err.error.message);
    })
  }
  usersData: any
  usersReferralUserIDView() {
    let url = `account/admin/user-management/get-user-data-list?userData=${this.userId}`
    this.service.get(url).subscribe((res: any) => {
      if (res['status'] == 200) {
        this.usersData = res['data']['data']['data']
        console.log(this.usersData);

        this.service.hideSpinner();
        this.service.toasterSucc(res.message);
      } else {
        this.service.hideSpinner();
        this.service.toasterErr(res.message)
      }
    }, (err) => {
      this.service.hideSpinner();
      this.service.toasterErr(err.error.message);
    })
  }


}
