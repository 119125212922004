<main class="middle-content">
    <div class="page_title_block">
        <h1 class="page_title" style="color:#26272d">WITHDRAWAL LIMIT</h1>
    </div>
    <div class="content-section">
        <div class="outer-box">
            <div class="global-table no-radius p0">
                <div class="tab-content1">
                    <div class="tab-pane1">
                        <div class="user-main-head">
                            <p
                                style="margin-top: -3%;font-size: 124%;background-color: white; width: 10%; padding-left: 1%;">
                                Filter By</p>
                            <form [formGroup]="withdrawlForm">
                                <div class="row form-group">

                                    <div class="col-md-4" style="padding-top: 1%;">
                                        <input type="text" name="Coin Name" placeholder="Search by coin name"
                                            class="form-control" formControlName="withdraw">
                                    </div>

                                    <div class="col-md-2 user-lst" style="margin-top: 1%; ">
                                        <div class="two-btnline">
                                            <div class="text-left" (click)="search()">
                                                <button type="submit" [disabled]="!withdrawlForm.valid"
                                                    class="btn  btn-theme cus-mr">SEARCH</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div class="table-responsive">
                            <table class="table table-bordered" aria-describedby="user list">
                                <thead>
                                    <tr class="no_wrap_th">
                                        <th scope="col">S.No</th>
                                        <th scope="col">Coin</th>
                                        <th scope="col">Withdrawal Limit</th>
                                        <th scope="col" class="action_td_btn3">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr
                                        *ngFor="let data of withdrawData | paginate:{itemsPerPage:20,currentPage:pageNumber,totalItems:withdrawData?.length}; let i=index">
                                        <td>{{20*(pageNumber-1)+i+1}}</td>
                                        <td>{{data?.coinShortName}}</td>
                                        <td>{{data?.withdrawalAmount}}</td>
                                        <td class="action_td_btn3">
                                            <a (click)="viewLimit(data?.coinShortName)"><em class="fa fa-eye"
                                                    style="font-size: 17px;color:black"></em></a>
                                        </td>
                                    </tr>
                                    <tr *ngIf="withdrawData?.length==0">
                                        <td colspan="10" vertical-align="middle">
                                            <div class="no-record">
                                                <div class="no-recordin">
                                                    <H5 style="margin-left: 10%;">No record found</H5>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <div class="custom-pagination mt20 " style="display: flex; justify-content:flex-end"
                                *ngIf="withdrawData?.length>0">
                                <pagination-controls (pageChange)="pageNumber = $event"></pagination-controls>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
</main>

<footer>
    <div class="container-fluid">
        <p class="copyright">CRED Development© 2022 All Rights Reserved. <strong class="theme-text-color"></strong>
        </p>
    </div>
</footer>